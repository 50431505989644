export type UserId = number;
export type RoomIdent = string;
export type Username = string;
export type GameId = number;
export type CallerPeerId = string;

export type UserMeta = {
  userId: UserId;
  username: Username;
  callerPeerId: CallerPeerId|null; // null when no stream activated
};

export type CallsPlan = {
  userIdMap: { [userId: number]: CallerPeerId };
  routes: Array<CallRoute>;
};
export type CallRoute = [CallerPeerId, CallerPeerId];

export type LoginJwtToken = {
  sub: string;
  id: UserId;
  // exp: number
  email: string | null;
  username: Username;
};

export type RoomState = {
  users: Array<UserMeta>;
  callRoutes: Array<CallRoute>;
  ident: RoomIdent;
  gameState: MutableGameState;
  activeGameId: GameId | null;
  activeGameCrosswordIdent: string | null;
};

export type EntryGrid = {
  [index: string]: string | null;
};

export type FocusIdent = string; // Identifier for the thing doing the focusing
export type FocusIndex = number;
export enum FocusDir {
  Across = "across",
  Down = "down",
}
export type FocusMeta = {
  index: FocusIndex;
  dir: FocusDir;
};

export type MutableGameState = {
  // Ident is FocusIdent [ts(1336)]
  focuses: { [ident: string]: FocusMeta };
  entryGrid: EntryGrid;
};

// Someday have multiple focoses?
export const GLOBAL_FOCUS_IDENT = "Main";
