import { createContext } from "react";

type ContextProps = {
  jwtToken: string | null;
  setJwtToken: (jwtToken: string) => void;
};

const JwtTokenContext = createContext<ContextProps>({
  jwtToken: null,
  setJwtToken: () => {},
});

export default JwtTokenContext;
