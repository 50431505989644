import { map, zip, reduce } from "lodash";
import { FocusDir } from "../../appshared/types";

import {
  AnswerChar,
  AnswerGrid,
  GenericGrid,
  ClueStartGrid,
  Size,
  ClueDirOwnerGrid,
} from "./types";

/**
 * For an array representing a 2-dimensional matrix of width `size`,
 * and for a given `index` into that array, return a new index
 * for access into that array such that the items at that array index
 * represent the transposition of that matrix.
 *
 * That is, for array X: [a b c d], representing the matrix [[a b c]
 *                                                           [d e f]]
 * return an index into array X, which represents the matrix [[a d]
 *                                                            [b e]
 *                                                            [c f]]
 *
 * @param {Number} index The array index into the array to convert
 * @param {Number} sizeScalar The "width" of the grid
 *
 */
function convertGridIndex(index: number, sizeScalar: number): number {
  return (index % sizeScalar) * sizeScalar + Math.floor(index / sizeScalar);
}

export function convertToDownGridIndex(index: number, { cols }: Size) {
  return convertGridIndex(index, cols);
}

export function convertToAcrossGridIndex(index: number, { rows }: Size) {
  return convertGridIndex(index, rows);
}

export function convertToDownGrid<T>(
  grid: GenericGrid<T>,
  size: Size
): GenericGrid<T> {
  return map(grid, (_, index) => grid[convertToDownGridIndex(index, size)]);
}

export function convertToAcrossGrid<T>(
  grid: GenericGrid<T>,
  size: Size
): GenericGrid<T> {
  return map(grid, (_, index) => grid[convertToAcrossGridIndex(index, size)]);
}

export function otherDir(dir: FocusDir) {
  return dir === FocusDir.Across ? FocusDir.Down : FocusDir.Across
}

/**
 *
 * @returns A grid where every element is the clue number to which that box belongs
 */
function buildClueGrid(
  grid: AnswerGrid,
  gridnums: ClueStartGrid,
  sizeScalar: number
): ClueDirOwnerGrid {
  let currentClueNum: number | null = null;
  const clueGrid = map(zip(grid, gridnums), ([char, num], i) => {
    if (char === undefined || num === undefined) {
      throw new Error("grid and gridnums must be of same length");
    }

    if (i % sizeScalar === 0) {
      currentClueNum = null;
    }

    if (char === AnswerChar.Blank) {
      currentClueNum = null;
      // TODO: Probably return null here
      return 0;
    } else {
      if (currentClueNum) {
        return currentClueNum;
      } else {
        currentClueNum = num;
        return currentClueNum;
      }
    }
  });
  return clueGrid;
}

export function buildClueGridAcross({
  grid,
  gridnums,
  size,
}: {
  grid: AnswerGrid;
  gridnums: ClueStartGrid;
  size: Size;
}): ClueDirOwnerGrid {
  return buildClueGrid(grid, gridnums, size.cols);
}

export function buildClueGridDown({
  grid,
  gridnums,
  size,
}: {
  grid: AnswerGrid;
  gridnums: ClueStartGrid;
  size: Size;
}): ClueDirOwnerGrid {
  return convertToAcrossGrid(
    buildClueGrid(
      convertToDownGrid(grid, size),
      convertToDownGrid(gridnums, size),
      size.rows
    ),
    size
  );
}

export function buildStartIndexByClueNum({
  gridnums,
}: {
  gridnums: ClueStartGrid;
}): { [clueNum: number]: number } {
  return reduce(
    gridnums,
    (accum, clueNum, index) => ({
      ...accum,
      [clueNum]: index,
    }),
    {}
  );
}
