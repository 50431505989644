
export type DetectedMediaDevices = {
    hasMicrophone: boolean,
    hasMicrophonePermission: boolean,
    hasCamera: boolean,
    hasCameraPermission: boolean
}

export async function detectMediaDevices(): Promise<DetectedMediaDevices> {

    let hasMicrophone = false
    let hasMicrophonePermission = false
    let hasCamera = false
    let hasCameraPermission = false

    const devices = await navigator.mediaDevices.enumerateDevices()
    
    devices.forEach((dev) => {
        if (dev.kind === "audioinput") {
            hasMicrophone = true
            if (dev.label) {
                hasMicrophonePermission = true
            }
        }
        if (dev.kind === "videoinput") {
            hasCamera = true
            if (dev.label) {
                hasMicrophonePermission = true
            }
        }
       
    })
    return {
        hasMicrophone,
        hasMicrophonePermission,
        hasCamera,
        hasCameraPermission
    }
}