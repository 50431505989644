
import { useEffect, FunctionComponent } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, TextField, FormHelperText, Button, FormControl } from '@mui/material';

import { JwtTokenRespSuccType, SignupBodyType, RandomUsernameRespSuccType, URL_RANDOM_USERNAME, RandomUsernameBodyType, URL_SIGNUP } from "../appshared/apiSchema";
import { apiPost } from "../utils/puzzliveApi";


type LoginChooseUsernameFormProps = {
  onTokenReceive: (jwtToken: string) => void;
}


const fetchSuggestedUsername = async function (): Promise<string> {
  try {
    const { suggestedUsername } = await apiPost<RandomUsernameRespSuccType, RandomUsernameBodyType>(URL_RANDOM_USERNAME, {})
    return suggestedUsername;
  } catch (err) {
    console.warn(`Failed to get suggested username, ${err}`)
    return "almond";
  }
}

// const makeSuggestedUsername = async function (): Promise<string> {
//   const adj = selectRandom(["Arrogant", "Average", "Baleful", "Wistful", "Wry", "Cunning", "Warty", "Timid", "Bashful", "Lilliputian"])
//   const noun = selectRandom(["Toad", "Wildebeast", "Frog", "Lawyer", "Driver", "Doctor", "Fireman", "Gamer", "Doggie"])
//   return Promise.resolve(`${adj}_${noun}`);
// }


const LoginChooseUsernameForm: FunctionComponent<LoginChooseUsernameFormProps> = ({ onTokenReceive }) => {

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue
  } = useForm();

  const updateSuggestedUsername = async () => {
    const suggestedUsername = await fetchSuggestedUsername()
    // const suggestedUsername = await makeSuggestedUsername()
    setValue("username", suggestedUsername)
  }

  const onSubmit = async (data: any) => {
    try {
      const { jwtToken } = await apiPost<JwtTokenRespSuccType, SignupBodyType>(URL_SIGNUP, {
        username: data.username,
      })
      onTokenReceive(jwtToken)
    } catch (err) {
      setError("submit", { type: "api", message: String(err) })
    }
  }

  useEffect(() => {
    updateSuggestedUsername()
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>

        <Box>
          {/* https://react-hook-form.com/api/usecontroller/controller */}
          <Controller
            name="username"
            defaultValue=""
            control={control}
            rules={{ required: "Username is required" }}
            render={({ field, fieldState }) =>
              <TextField
                label="Choose a Username"
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : "Your name as it will appear to other users"}
                InputProps={{ endAdornment: <Button onClick={() => updateSuggestedUsername()}>New Random</Button> }}
              />
            }
          />

          {/* <Button onClick={() => updateSuggestedUsername()}>Suggest</Button> */}

        </Box>

        <Box>
          <FormControl>
            <FormHelperText error={!!formState.errors.submit}>
              {formState.errors.submit?.message}
            </FormHelperText>

            <Button type="submit" variant="contained">Start</Button>
          </FormControl>
        </Box>


      </Box>
    </form>
  )
}

export default LoginChooseUsernameForm;