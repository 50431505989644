import { API_URL_BASE } from "../const";
import {
  ApiOptions,
  apiGet as _apiGet,
  apiPost as _apiPost
} from './api'



export async function apiGet<T>(
  path: string,
  opts: Partial<ApiOptions> = {}
) {
  return _apiGet<T>(`${API_URL_BASE}${path}`, opts)
}

export async function apiPost<TResp, TBody>(
  path: string,
  data: TBody,
  opts: Partial<ApiOptions> = {}
) {
  return _apiPost<TResp, TBody>(`${API_URL_BASE}${path}`, data, opts)
}


export function api(opts: ApiOptions) {
  return {
    post<TResp, TBody>(path: string, data: TBody): Promise<TResp> {
      return apiPost(path, data, opts);
    },
    get<T>(path: string): Promise<T> {
      return apiGet(path, opts);
    },
  };
}
