import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { FaMicrophone, FaMicrophoneSlash, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import styled from "styled-components";
import MiniAudioStreamViz from "./MiniAudioStreamViz";

const UserAudioEl = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: relative;
  box-sizing: border-box;
`;

const UsernameEl = styled.div`
  z-index: 2;
`;
const MutedBox = styled.div`
  z-index: 2;
`;

type UserAudioStreamProps = {
  stream: MediaStream;
  username: string;
  isSelf?: boolean;
  play?: boolean;
};


const UserAudioStream: FunctionComponent<UserAudioStreamProps> = ({
  isSelf = false,
  username,
  stream,
  play = false,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioTrack = stream.getTracks()[0];
  // Always start others as "muted", we do this mostly because its hard to get audio to play for sure
  // until a user interaction
  const [muted, setMuted] = useState<boolean>(!isSelf);

  useEffect(() => {
    audioTrack.enabled = !muted;
  }, [audioTrack, muted]);

  function mute() {
    audioTrack.enabled = false;
    setMuted(true);
  }

  function unmute() {
    audioTrack.enabled = true;
    if (audioRef.current) {
      audioRef.current.play();
    }
    setMuted(false);
  }

  if (stream && audioRef.current) {
    audioRef.current.srcObject = stream;
    audioRef.current.load();
    audioRef.current.volume = 1.0;
  }

  return (
    <UserAudioEl
      onClick={() => {
        muted ? unmute() : mute();
      }}
    >
      <UsernameEl>
        {username} {isSelf && "(you)"}
      </UsernameEl>
      <MutedBox>

        {isSelf ?
          (
            muted ?
              <FaMicrophoneSlash title="Unmute yourself" />
              :
              <FaMicrophone title="Mute yourself" />
          )
          :
          (
            muted ?
              <FaVolumeMute
                title={`Unmute ${isSelf ? "yourself" : username}`}
              />
              :
              <FaVolumeUp title={`Mute ${isSelf ? "yourself" : username}`} />
          )}

      </MutedBox>
      {play && (
        <audio
          key="theaudio"
          ref={audioRef}
          autoPlay
          onError={(error) => {
            console.error("UserAudioStream error", { stream, error });
          }}
          onPlay={() => {
            console.debug("Stream played", { stream });
          }}
          onPause={() => {
            console.debug("Stream paused", { stream });
          }}
          onLoadedMetadata={() => {
            console.debug("Stream loaded metadata", { stream });
          }}
          onLoad={() => {
            console.debug("Stream load", { stream });
          }}
          onStalled={() => {
            console.debug("Stream stalled", { stream });
          }}
        />
      )}
      <MiniAudioStreamViz
        stream={stream}
        style={{
          width: "60px",
          height: "60px",
          position: "absolute",
          left: 0,
          bottom: 0,
        }}
      />
    </UserAudioEl>
  );
};

export default UserAudioStream;
