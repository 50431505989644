import { formatZeroPad } from "./stringUtils";

export const dateObjToDateArray = (date: Date): Array<number> => [
  date.getFullYear(),
  date.getMonth() + 1,
  date.getDate(),
];

export const dateObjToStr = (date: Date): string => {
  const [year, month, day] = dateObjToDateArray(date);
  return `${year}/${formatZeroPad(month)}/${formatZeroPad(day)}`;
};

export function getDayOfWeek(date: Date): string {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][date.getDay()];
}
