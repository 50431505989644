import jwt from "jsonwebtoken";

export function decodeJwt<T>(jwtToken: string): T {
  const decoded = jwt.decode(jwtToken, { json: true });
  if (!decoded) {
    throw new Error("Not a valid webtoken");
  }
  return decoded as T;
}


export function saveJwtTokenFromURLParamToLocalStorage(urlParamName: string, localStorageKey: string) {
  const url = new URL(window.location.toString())
  const jwtToken = url.searchParams.get(urlParamName)
  if (jwtToken) {
    url.searchParams.delete(urlParamName)
    localStorage.setItem(localStorageKey, jwtToken)
    window.history.replaceState(null, "", url.toString())
  }
}