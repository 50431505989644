import { useCallback, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { GameItemApiType } from "../appshared/apiSchema";
import {
  JOIN_ROOM_EVENT,
  UPDATE_ROOM_STATE_EVENT,
  UpdateRoomStateEventData,
  JoinRoomEventData,
  UPDATE_GAME_STATE_EVENT,
  UpdateGameStateEventData,
  OH_NO_RESET_STATE_EVENT,
  ExitGameEventData,
  EXIT_GAME_EVENT,
  ChooseGameEventData,
  CHOOSE_GAME_EVENT,
  AUDIO_READY,
  AudioReadyEventData,
  LEAVE_ROOM_EVENT,
  LeaveRoomEventData,
} from "../appshared/eventSchema";
import { RoomState, MutableGameState } from "../appshared/types";

type UseRoomProps = {
  socket: Socket; // Must be a connected socket
  roomIdent: string;
};


export default function useRoom({ socket, roomIdent }: UseRoomProps) {
  const [roomState, setRoomState] = useState<RoomState | null>(null);

  const updateGameState = useCallback((gameState: MutableGameState)=> {
    socket.emit(UPDATE_GAME_STATE_EVENT, {
      gameState,
      roomIdent,
    } as UpdateGameStateEventData);
  }, [socket, roomIdent])

  const exitGame = useCallback(()=> {
    socket.emit(EXIT_GAME_EVENT, { roomIdent } as ExitGameEventData);
  }, [socket,roomIdent])

  const chooseGame = useCallback((game: GameItemApiType)=> {
    socket.emit(CHOOSE_GAME_EVENT, {
      roomIdent,
      gameId: game.id,
    } as ChooseGameEventData);
  }, [ socket, roomIdent])

  const tellAudioReady = useCallback(()=> {
    socket.emit(AUDIO_READY, { roomIdent} as AudioReadyEventData)
  }, [socket, roomIdent])

  useEffect(() => {
    socket.on(UPDATE_ROOM_STATE_EVENT, (data: UpdateRoomStateEventData) => {
      console.log("Update room state", data);
      setRoomState(data);
    });

    socket.on(OH_NO_RESET_STATE_EVENT, () => {
      // The server (probably due to restart?) lost track of us, we should reset
      setRoomState(null);
    });

    socket.emit(JOIN_ROOM_EVENT, { roomIdent } as JoinRoomEventData);

    return function tearDown() {
      setRoomState(null)
      socket.emit(LEAVE_ROOM_EVENT, { roomIdent } as LeaveRoomEventData)
    }
  }, [socket, roomIdent]);

  return {
    roomState,
    updateGameState,
    exitGame,
    chooseGame,
    tellAudioReady
  };
}
