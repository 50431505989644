type AnalyzerProps = {
  stream: MediaStream;
  fftSize?: number;
};

export function makeAnalyzer({
  stream,
  fftSize = 256,
}: AnalyzerProps): AnalyserNode {
  const audioCtx = new AudioContext();

  const analyser = audioCtx.createAnalyser();
  analyser.minDecibels = -90; // 255 bytes
  analyser.maxDecibels = -10; // 0 bytes
  analyser.smoothingTimeConstant = 0.9; // 0.85;
  // An unsigned integer, representing the window size of the FFT, given in number of samples. A higher value will result in more details in the frequency domain but fewer details in the time domain.
  // Resulting in 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, and 32768. (Default for analyzer node is 2048.)
  analyser.fftSize = fftSize; // fourier transform window size

  // var distortion = audioCtx.createWaveShaper();
  // var gainNode = audioCtx.createGain();
  // var biquadFilter = audioCtx.createBiquadFilter();
  // var convolver = audioCtx.createConvolver();

  const source = audioCtx.createMediaStreamSource(stream);
  source.connect(analyser);
  // distortion.connect(biquadFilter);
  // biquadFilter.connect(gainNode);
  // convolver.connect(gainNode);
  // gainNode.connect(analyser);
  return analyser;
}

export function makeFreqBuffer(analyser: AnalyserNode): Uint8Array {
  return new Uint8Array(analyser.frequencyBinCount);
}
