import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components';

import { CallRoute, UserMeta } from '../appshared/types';
import usePeerStreams from '../hooks/usePeerStreams';
import useLocalAudioStream from '../hooks/useLocalAudioStream';
import UserAudioStream from './UserAudioStream'
import useDetectMediaDevices from '../hooks/useDetectMediaDevices';
import { FaMicrophoneSlash } from 'react-icons/fa';
import { FiWifiOff } from 'react-icons/fi'
import { useEffect } from 'react';

const AudioChatEl = styled.div`
  padding: 5px 0;
  position: relative;
`;

const UserInviteBox = styled.div`
  display: inline-block;
  margin: 5px;
  vertical-align: top;
  border: 1px dashed grey;
  background-color: transparent;
  border-radius: 3px;
  color: white;
  width: 60px;
  height: 60px;
  word-break: break-all;
  padding: 2px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
`;

const ToDoBox = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #557ed6;
  color: white;
  padding: 3px 5px;
`

const UserBox = styled.div`
  display: inline-block;
  margin: 5px;
  width: 60px;
  height: 60px;
  vertical-align: top;
  border: 1px solid grey;
  background-color: #eee;
  border-radius: 3px;
  box-sizing: border-box;
  color: black;
  text-align: center;
  overflow: hidden;
`;

const UserNoAudioEl = styled.div`
  text-align: center;
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: relative;
`;

type AudioChatProps = {
  thisUser: UserMeta
  otherUsers: Array<UserMeta>
  callRoutes: Array<CallRoute>
  onInvitingStart: () => void
  onTellAudioReady: () => void
}

const AudioChat: FunctionComponent<AudioChatProps> = ({ thisUser, otherUsers, callRoutes, onInvitingStart, onTellAudioReady }) => {
  const detectedDevices = useDetectMediaDevices()
  const [streamTryEnable, setStreamTryEnable] = useState(false)
  const { stream, error } = useLocalAudioStream({ enabled: streamTryEnable })
  useEffect(() => {
    if (stream) {
      onTellAudioReady()
    }
  }, [stream, onTellAudioReady])
  const { peerStreams } = usePeerStreams({ user: thisUser, stream, users: otherUsers, callRoutes });
  function startAudio() {
    setStreamTryEnable(true)
  }
  return (
    <AudioChatEl>
      {
        !detectedDevices ?
          <ToDoBox>Checking devices...</ToDoBox>
          : !detectedDevices.hasMicrophone ?
            <ToDoBox>You don't seem to have a microphone connected—cannot join chat</ToDoBox>
            : !streamTryEnable && !detectedDevices.hasMicrophonePermission ?
              <ToDoBox onClick={startAudio}>You must grant permission to use your microphone <button onClick={startAudio}>Grant</button></ToDoBox>
              : !streamTryEnable ?
                <ToDoBox onClick={startAudio}>Start audio chatting <button onClick={startAudio}>Start</button></ToDoBox>
                : error ?
                  <ToDoBox>Error in your audio stream {error} <button onClick={() => window.location.reload()}>Refresh</button></ToDoBox>
                  : null
      }
      {
        <UserBox>
          {stream ?
            <UserAudioStream isSelf username={thisUser.username} stream={stream} />
            :
            <UserNoAudioEl onClick={startAudio}>
              {thisUser.username} (you) <br />
              <FaMicrophoneSlash title="Start audio" />
            </UserNoAudioEl>
          }
        </UserBox>
      }
      {
        otherUsers.map(({ userId, username }) => {
          if (userId === thisUser.userId) {
            return null
          }
          const matchedUserStream = peerStreams.find(({ userId: streamUserId }) => streamUserId === userId)
          return (
            <UserBox key={userId}>
              {
                matchedUserStream ?
                  <UserAudioStream username={username} stream={matchedUserStream.stream} play />
                  :
                  <UserNoAudioEl >
                    {username} <br />
                    <FiWifiOff />
                  </UserNoAudioEl>

              }
            </UserBox>
          );
        })
      }

      <UserInviteBox onClick={onInvitingStart}>
        Invite somebody
      </UserInviteBox>

    </AudioChatEl>
  )
}

export default AudioChat