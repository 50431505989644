import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Socket } from "socket.io-client";
import styled from "styled-components";
import { HiAdjustments, HiLogout, HiUserCircle, HiOutlinePencil } from "react-icons/hi";
import { Button, TextField, Drawer, Box, Switch, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import {
  LoginJwtToken,
  RoomIdent,
  RoomState,
  UserMeta,
  MutableGameState,
} from "../appshared/types";
import JwtTokenContext from "../contexts/jwtTokenContext";
import { decodeJwt } from "../utils/jwt";

import useConnectedSocket from "../hooks/useConnectedSocket";
import useRoom from "../hooks/useRoom";
import GameManager from "./GameManager";
import Games from "./Games";
import { GameItemApiType } from "../appshared/apiSchema";
import { H1, H2 } from "./Headers";
import { getDayOfWeek } from "../utils/date";
import AudioChat from "./AudioChat";
import SendClaimEmailForm from "./SendClaimEmailForm";


const IS_SHOWING_INCORRECT_IN_WORD_LOCALSTORAGE_KEY = "isShowingIncorrectInWord";
const IS_SHOWING_INCORRECT_IN_PUZZLE_LOCALSTORAGE_KEY = "isShowingIncorrectInPuzzle";


const OptionsBar = styled.div`
  border-bottom: 1px solid #505052;
  margin-bottom: 0px;
  text-align: left;
  background-color: #3c3d3d;
`;

const RoomBar = styled.div`
  border-bottom: 1px solid #505052;
  margin-bottom: 0px;
  text-align: left;
  background-color: #393a3a;
`;

// const RoomNameInputEl = styled.input`
//   input[type=text]& {
//     margin-left: 4px;
//     width: 14rem;
//     vertical-align: top;
//     background-color: #eef5fd;
//     border-radius: 3px;
//     font-size:1.3rem;
//     padding: 4px;
//     margin-right: 2px;
//   }
// `;

const GamesBox = styled.div`
  margin: 15px 5px;
  position: relative;
`;

const ConnectBox = styled.div`
  padding-top:100px;
`

const PuzzleTitleEl = styled.div`
  margin: 5px;
`;

type BasicRoomProps = {
  roomIdent: RoomIdent;
  onLogout: () => void;
  onRoomIdentChange: (roomIdent: RoomIdent) => void;
};


const RoomWrapWithJwtToken: FunctionComponent<BasicRoomProps> = (props) => {
  return (
    <JwtTokenContext.Consumer>
      {({ jwtToken }) => {
        if (!jwtToken) {
          return <>Not logged in</>;
        }
        return <RoomWrapWithSocket jwtToken={jwtToken} {...props} />;
      }}
    </JwtTokenContext.Consumer>
  );
};

type RoomWrapWithSocketProps = {
  jwtToken: string;
} & BasicRoomProps;


const RoomWrapWithSocket: FunctionComponent<RoomWrapWithSocketProps> = ({
  jwtToken,
  ...props
}) => {
  const { socket, connectError } = useConnectedSocket({ jwtToken });

  if (!socket) {
    if (connectError) {
      return <ConnectBox>
        Connect Error: {JSON.stringify(connectError)} <br />
        <button onClick={props.onLogout}>Log In Again</button>
      </ConnectBox>;
    }
    return (
      <ConnectBox>
        Connecting to game server
      </ConnectBox>
    )

  }

  return (
    <RoomWrapRoomState
      socket={socket}
      jwtToken={jwtToken}
      connectError={connectError}
      {...props}
    />
  );
};

type RoomWrapRoomStateProps = {
  socket: Socket;
  connectError: string | null;
} & RoomWrapWithSocketProps;

const RoomWrapRoomState: FunctionComponent<RoomWrapRoomStateProps> = ({
  socket,
  jwtToken,
  roomIdent,
  ...props
}) => {
  const { roomState, updateGameState, exitGame, chooseGame, tellAudioReady } = useRoom({ socket, roomIdent });

  if (!roomState) {
    return <>Loading Room</>;
  }

  return (
    <RoomWrapThisUser
      jwtToken={jwtToken}
      socket={socket}
      roomState={roomState}
      roomIdent={roomIdent}
      onGameChoose={chooseGame}
      onGameExit={exitGame}
      updateGameState={updateGameState}
      onTellAudioReady={tellAudioReady}
      {...props}
    />
  );
};

type RoomWrapThisUserProps = {
  roomState: RoomState;
  onGameChoose: (game: GameItemApiType) => void;
  updateGameState: (gameState: MutableGameState) => void;
  onGameExit: () => void;
  onTellAudioReady: () => void
} & RoomWrapRoomStateProps;

const RoomWrapThisUser: FunctionComponent<RoomWrapThisUserProps> = ({
  roomState,
  jwtToken,
  ...props
}) => {
  const jwtTokenDecoded: LoginJwtToken = useMemo(
    () => decodeJwt<LoginJwtToken>(jwtToken),
    [jwtToken]
  );
  const thisUser = useMemo(
    () => roomState.users.find((u) => u.userId === jwtTokenDecoded.id),
    [roomState.users, jwtTokenDecoded.id]
  );
  if (!thisUser) {
    return <>User missing...</>
    // throw Error(`Could not find userId in users`);
  }

  return (
    <Room
      {...{
        thisUser,
        roomState,
        loginJwtToken: jwtTokenDecoded,
        jwtToken,
        ...props,
      }}
    />
  );
};

type RoomProps = {
  thisUser: UserMeta;
  loginJwtToken: LoginJwtToken,
} & RoomWrapThisUserProps;

function makeInviteUrl(roomIdent: string) {
  return window.location.toString()
}

const Room: FunctionComponent<RoomProps> = ({
  thisUser,
  roomState,
  connectError,
  jwtToken,
  loginJwtToken,
  updateGameState,
  onRoomIdentChange,
  onLogout,
  onGameChoose,
  onGameExit,
  onTellAudioReady
}) => {
  const [roomIdentInput, setRoomIdentInput] = useState<string>("");
  const [isChoosingRoom, setIsChoosingRoom] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isDisplayingSaveEmail, setIsDisplayingSaveEmail] = useState(false);
  const [sentToEmailAddress, setSentToEmailAddress] = useState<string | null>(null);
  const [isShowingIncorrectInWord, setIsShowingIncorrectInWord_] = useState(
    JSON.parse(localStorage.getItem(IS_SHOWING_INCORRECT_IN_WORD_LOCALSTORAGE_KEY) || "false")
  );
  const [isShowingIncorrectInPuzzle, setIsShowingIncorrectInPuzzle_] = useState(
    JSON.parse(localStorage.getItem(IS_SHOWING_INCORRECT_IN_PUZZLE_LOCALSTORAGE_KEY) || "false")
  );
  const {
    ident,
    users,
    gameState,
    activeGameId,
    activeGameCrosswordIdent,
    callRoutes,
  } = roomState;

  // Cache the playstate immediately when its set so there is less confusing delay
  const [localGameStateCache, setLocalGameStateCache] = useState(gameState);
  useEffect(() => {
    setLocalGameStateCache(gameState);
  }, [gameState]);

  function updateAndCacheGameState(gameState: MutableGameState) {
    setLocalGameStateCache(gameState);
    updateGameState(gameState);
  }
  // End cache

  function beginChoosingRoom() {
    setRoomIdentInput(ident);
    setIsChoosingRoom(true);
  }

  function setIsShowingIncorrectInWord(value: boolean) {
    setIsShowingIncorrectInWord_(value);
    localStorage.setItem(IS_SHOWING_INCORRECT_IN_WORD_LOCALSTORAGE_KEY, JSON.stringify(value));
  }
  function setIsShowingIncorrectInPuzzle(value: boolean) {
    setIsShowingIncorrectInPuzzle_(value);
    localStorage.setItem(IS_SHOWING_INCORRECT_IN_PUZZLE_LOCALSTORAGE_KEY, JSON.stringify(value));
  }


  return (
    <div className="Room">

      <Drawer
        anchor="top"
        open={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
      >
        <Box>

          <List>
            <ListItem >
              <H1>Menu</H1>
            </ListItem>
            <ListItem >
              <ListItemButton onClick={() => setIsDisplayingSaveEmail(true)}>
                <ListItemIcon>
                  <HiUserCircle />
                </ListItemIcon>
                <ListItemText primary={loginJwtToken.email || 'Save Game with Email'} />
              </ListItemButton>
            </ListItem>
            <ListItem >
              <ListItemButton onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  onLogout()
                }
              }}>
                <ListItemIcon>
                  <HiLogout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
            <ListItem >
              <ListItemButton onClick={() => { setIsOptionsOpen(false); beginChoosingRoom() }}>
                <ListItemIcon>
                  <HiOutlinePencil />
                </ListItemIcon>
                <ListItemText primary="Change Room Name" />
              </ListItemButton>
            </ListItem>
            <ListItem >
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowingIncorrectInPuzzle}
                    onChange={() => setIsShowingIncorrectInPuzzle(!isShowingIncorrectInPuzzle)}
                  />
                }
                label="Show incorrect answers in puzzle"
              />
            </ListItem>
            <ListItem >
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowingIncorrectInWord}
                    onChange={() => setIsShowingIncorrectInWord(!isShowingIncorrectInWord)}
                  />
                }
                label="Show incorrect answers in word"
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Drawer
        anchor="top"
        open={isChoosingRoom}
        onClose={() => setIsChoosingRoom(false)}
      >
        <Box>
          <H1>Change room name</H1>
          <form onSubmit={(e) => { e.preventDefault(); onRoomIdentChange(roomIdentInput); setIsChoosingRoom(false); }}>
            <Box>
              <TextField
                label="New Room Name"
                inputProps={{ autoFocus: true, autoCapitalize: "none" }}
                value={roomIdentInput || ""}
                onChange={(e) => setRoomIdentInput(e.target.value.toLowerCase())}
              />
              <Button type="submit">Join</Button>
            </Box>
          </form>
        </Box>
      </Drawer>

      <Drawer
        anchor="top"
        open={isInviting}
        onClose={() => setIsInviting(false)}
      >
        <H1>Send this link for someone else to join the game</H1>
        <label>
          <br />
          <input
            type="text"
            style={{ width: "100%" }}
            autoFocus
            onFocus={(e) => { e.target.select(); document.execCommand("copy"); }}
            readOnly
            value={makeInviteUrl(ident)}
          />
          <Button onClick={() => document.execCommand("copy")}>Copy</Button>
        </label>
      </Drawer>

      <Drawer
        anchor="top"
        open={isDisplayingSaveEmail}
        onClose={() => setIsDisplayingSaveEmail(false)}
      >
        {
          sentToEmailAddress ?
            <H2>Check your inbox. We sent you an email to {sentToEmailAddress}</H2>
            :
            <>
              <Box sx={{ margin: '10px' }}>
                <H1>Save your progress by setting your email. </H1>
                <H2>We'll send you a message to confirm</H2>
                <SendClaimEmailForm jwtToken={jwtToken} onSent={(emailRecipient) => {
                  // TODO: Maybe set this to false eventually?
                  setSentToEmailAddress(emailRecipient)
                }} />
              </Box>
            </>
        }

      </Drawer>

      <OptionsBar>
        <Button startIcon={<HiAdjustments />} size="small" variant="contained" onClick={() => setIsOptionsOpen(true)}>
          Menu
        </Button>
      </OptionsBar>

      <RoomBar>

        <AudioChat thisUser={thisUser} otherUsers={users} callRoutes={callRoutes} onTellAudioReady={onTellAudioReady} onInvitingStart={() => setIsInviting(true)} />

      </RoomBar>

      {
        activeGameId && activeGameCrosswordIdent ? (
          <>
            <PuzzleTitleEl>
              Puzzle {activeGameCrosswordIdent}{" • "}
              {((x) => (x ? x : ""))(
                getDayOfWeek(new Date(activeGameCrosswordIdent))
              )}{" "}
              <button onClick={onGameExit}>Exit</button>
            </PuzzleTitleEl>

            <GameManager
              showIncorrectInPuzzle={isShowingIncorrectInPuzzle}
              showIncorrectInWord={isShowingIncorrectInWord}
              dateStr={activeGameCrosswordIdent}
              gameState={localGameStateCache}
              connectError={connectError}
              onGameStateChange={updateAndCacheGameState}
            />
          </>
        ) : (
          <>
            <GamesBox>

              <Games onGameChoose={onGameChoose} />
            </GamesBox>
          </>
        )
      }
    </div >
  );
};

export default RoomWrapWithJwtToken;
