export const makeId = (length: number) =>
  Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);


export function makeEmojiId(length: number = 3) {
  var result = "";
  // const characters       = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('').concat(['🔥','❤️','😭','🙄','🙏','☃️','🎃','🦠','🌎','🐒']);
  const characters = [
    "🔥",
    "❤️",
    "😭",
    "🙄",
    "🙏",
    "☃️",
    "🎃",
    "🦠",
    "🌎",
    "🐒",
    "🇺🇸",
    "🦫",
    "👍🏼",
    "👎🏻",
    "😀",
    "🤯",
    "🌭",
    "🧀",
    "🍌",
    "🍉",
    "💩",
  ];
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * charactersLength)];
  }
  return result;
}
