import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import "./App.css";
import { createTheme, ThemeProvider, CircularProgress } from '@mui/material'

import { APP_VER } from "../const";
import Login from "./Login";
import Room from "./Room";
import JwtTokenContext from "../contexts/jwtTokenContext";
import { RoomIdent } from "../appshared/types";
import { makeId } from "../utils/id";

import { saveJwtTokenFromURLParamToLocalStorage } from "../utils/jwt";

const JWT_TOKEN_KEY = "jwt-token";
const LAST_ROOM_KEY = "last-room";

const AppEl = styled.div`
  background-color: #282c34;
  color: white;
  overflow: hidden;
`;

const FooterEl = styled.div`
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  color: #aaa;
`;

function getRoomIdentFromLocation() {
  return (window.location.pathname || '/').slice(1).toLowerCase()
}



function getSetFirstRoomIdent() {
  // const urlParams = new URLSearchParams(window.location.search);
  // const roomFromParam = urlParams.get("room");
  const roomFromParam = getRoomIdentFromLocation()
  if (roomFromParam) {
    localStorage.setItem(LAST_ROOM_KEY, roomFromParam);
    return roomFromParam.toLowerCase();
  }
  const roomFromStorg = localStorage.getItem(LAST_ROOM_KEY);
  if (roomFromStorg) {
    return roomFromStorg.toLowerCase();
  }

  return makeId(5).toLowerCase();
}

function AppLoading() {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    saveJwtTokenFromURLParamToLocalStorage("loginToken", JWT_TOKEN_KEY)
    setIsLoading(false)
  }, [])

  return (
    isLoading ? <CircularProgress /> : <App />
  )
}

function App() {


  const [jwtToken, setJwtToken] = useState(localStorage.getItem(JWT_TOKEN_KEY));
  // TODO: make roomIdent changable
  // const [roomIdent, setRoomIdent] = useState<string>(getSetFirstRoomIdent());
  const roomIdent = useMemo<string>(() => getSetFirstRoomIdent(), [])

  function logout() {
    localStorage.removeItem(JWT_TOKEN_KEY);
    setJwtToken(null);
  }

  function joinRoom(roomIdent: RoomIdent) {
    window.location.pathname = `/${roomIdent}`;
    localStorage.setItem(LAST_ROOM_KEY, roomIdent);
  }

  useEffect(() => {
    if (getRoomIdentFromLocation() !== roomIdent) {
      // Probably loaded from localStorage, so set pathname correctly
      window.history.replaceState(roomIdent, "", `${roomIdent}`);
    }
    // We only want to do this on page load
    // eslint-disable-next-line
  }, [])

  const theme = createTheme({
    palette: {
      mode: 'dark'
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <JwtTokenContext.Provider value={{ jwtToken, setJwtToken }}>
        <AppEl>
          <div>
            {jwtToken ? (
              <div>
                <Room
                  roomIdent={roomIdent}
                  onRoomIdentChange={joinRoom}
                  onLogout={logout}
                />
              </div>
            ) : (
              <Login
                onTokenReceive={(j: string) => {
                  localStorage.setItem(JWT_TOKEN_KEY, j);
                  setJwtToken(j);
                }}
              />
            )}
          </div>

          <FooterEl>
            <div style={{ flexGrow: 1 }}></div>
            <div>(version: {APP_VER})</div>
          </FooterEl>
        </AppEl>
      </JwtTokenContext.Provider>
    </ThemeProvider>
  );
}

export default AppLoading;
