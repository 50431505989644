import React, { useEffect } from "react";
import { map, range } from "lodash";

import styled, { css } from "styled-components";

import Box, { getBoxId } from "./Box";
import useClueNumGridByDir from "./useClueNumGridByDir";
import { FocusDir, FocusIndex } from "../../appshared/types";
import { AnswerGrid, Circles, ClueStartGrid, ErrorGrid, GenericGrid, Size } from "./types";

interface GridElemProps {
  size: Size;
}

const GridElem = styled.div<GridElemProps>`
  display: grid;
  grid-gap: 0px;
  background-color: #fff;
  color: #000;
  width: 100%;

  ${(props) =>
    props.size &&
    css`
      grid-template-columns: ${map(
      range(props.size.cols),
      () => `${100 / props.size.cols}% `
    )};
    `}
`;

type GridProps = {
  size: Size;
  grid: GenericGrid<string>;
  circles?: Circles | null;
  gridnums: ClueStartGrid;
  errors: ErrorGrid;
  focusDir: FocusDir;
  focusIndex: FocusIndex;
  // prevFocus,
  // selectedClueGrid,
  onFocus: (index: number) => void;
  onLeft: () => void;
  onRight: () => void;
  onUp: () => void;
  onDown: () => void;
  onPrev: () => void;
  onNext: () => void;
  onGridIndexValueChange: (char: string, index: number) => void;
  onGridIndexClear: (index: number) => void;
};

function Grid({
  size,
  grid,
  circles,
  gridnums,
  errors,
  focusDir,
  focusIndex,
  onFocus,
  onLeft,
  onRight,
  onUp,
  onDown,
  onPrev,
  onNext,
  onGridIndexValueChange,
  onGridIndexClear,
}: GridProps) {
  useEffect(() => {
    const elem = document.getElementById(getBoxId(focusIndex));
    if (elem) {
      elem.focus();
    }
  }, [focusIndex]);

  // TODO: There is no need to recalculate this
  // Start Calc selected clue grid
  const clueNumGrid = useClueNumGridByDir({
    grid: grid as AnswerGrid,
    gridnums,
    size,
  })[focusDir];
  const clueNum = clueNumGrid[focusIndex];
  const selectedClueGrid = map(clueNumGrid, (num) => clueNum === num);

  // End selected clue grid

  return (
    <GridElem size={size}>
      {map(grid, (char, index) => (
        <Box
          key={index}
          index={index}
          isCorrect={null}
          hasError={!!((errors && errors[index]) || false)}
          hasCircle={!!(circles ? circles[index] : null)}
          size={size}
          char={char}
          num={gridnums[index]}
          isPartOfSelectedClue={selectedClueGrid[index] || false}
          isFocused={index === focusIndex}
          onFocus={(e) => {
            onFocus(index);
          }}
          onCharChange={(char) => onGridIndexValueChange(char, index)}
          onCharClear={() => onGridIndexClear(index)}
          onMouseDown={() => {
            if (index === focusIndex) {
              // If this is already in focus we still want to trigger a dir change
              onFocus(index);
            }
          }}
          {...{ onUp, onLeft, onRight, onDown, onNext, onPrev }}
        />
      ))}
    </GridElem>
  );
}

export default Grid;
