
export class DisplayableError extends Error {}

export class DisplayableApiError extends DisplayableError { }

export type ApiOptions = {
  jwtToken: string;
};

export async function apiFetch<T>(request: RequestInfo): Promise<T> {
  const response = await fetch(request);

  if (response.ok) {
    if (response.status === 204) {
      return response.text() as any;
    }
    return await response.json();
  }

  let errorJson = null;
  try {
    errorJson = await response.json();
  } catch (err) {
    throw new Error(`Unexpected error: ${err}`)
  } 
  if (errorJson?.message) {
    throw new DisplayableApiError(errorJson.message)
  } else {
    throw new Error(`Unexpected error: ${errorJson}`)
  }
}

export async function apiGet<T>(
  url: string,
  opts: Partial<ApiOptions> = {}
): Promise<T> {
  return await apiFetch<T>(
    new Request(`${url}`, {
      method: "GET",
      headers: {
        ...(opts.jwtToken ? { Authorization: `Bearer ${opts.jwtToken}` } : {}),
      },
    })
  );
}

export async function apiPost<TResp, TBody>(
  url: string,
  data: TBody,
  opts: Partial<ApiOptions> = {}
): Promise<TResp> {
  return await apiFetch<TResp>(
    new Request(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(opts.jwtToken ? { Authorization: `Bearer ${opts.jwtToken}` } : {}),
      },
      body: JSON.stringify(data),
    })
  );
}

export function api(opts: ApiOptions) {
  return {
    post<TResp, TBody>(url: string, data: TBody): Promise<TResp> {
      return apiPost(url, data, opts);
    },
    get<T>(url: string): Promise<T> {
      return apiGet(url, opts);
    },
  };
}
