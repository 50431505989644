// Inspired by https://github.com/mdn/voice-change-o-matic

import React, {
  FunctionComponent,
  useRef,
  CSSProperties,
  useEffect,
} from "react";
import { makeAnalyzer, makeFreqBuffer } from "../utils/audioAnalyzer";

type MiniAudioStreamVizProps = {
  stream: MediaStream;
  barGap?: number;
  log?: boolean;
  // Must be between 1 and 10,
  freqGranularity?: number;
  // True if the vizualization should be dark (to appear on light background)
  dark?: boolean;
  style?: CSSProperties;
};

type AnimateFnProps = {
  analyser: AnalyserNode;
  buffer: Uint8Array;
  canvasCtx: CanvasRenderingContext2D;
  width: number;
  height: number;
};

const BUFFER_MAX = 255;

const MiniAudioStreamViz: FunctionComponent<MiniAudioStreamVizProps> = ({
  stream,
  freqGranularity = 1,
  barGap = 0,
  dark = true,
  log = false,
  style = {},
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();
  // An unsigned integer, representing the window size of the FFT, given in number of samples. A higher value will result in more details in the frequency domain but fewer details in the time domain.
  // Resulting in 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, and 32768. (Default for analyzer node is 2048.)
  const fftSize = 2 ** (freqGranularity + 4);
  const bufferMax = log ? Math.log2(BUFFER_MAX) : BUFFER_MAX;

  useEffect(() => {
    const makeAnimateFn = ({
      analyser,
      buffer,
      canvasCtx,
      width,
      height,
    }: AnimateFnProps) =>
      function draw(time: number) {
        if (canvasCtx && canvasRef.current) {
          analyser.getByteFrequencyData(buffer);
          // if (Math.random() < 0.05) {
          //   console.log({ buffer)
          // }
          canvasCtx.clearRect(0, 0, width, height);

          var barWidth = width / buffer.length - barGap;
          var barHeight;
          var x = 0;

          for (var i = 0; i < buffer.length; i++) {
            // barHeight = buffer[i]
            const datum = log ? Math.log2(buffer[i]) : buffer[i];

            const factor = datum / bufferMax;
            barHeight = factor * height;
            // barHeight = buffer[i] / BUFFER_MAX * height;

            if (dark) {
              canvasCtx.fillStyle = `hsl(${(time / 20) % 255}, 50%, ${
                100 - (factor * 100 + 20)
              }%)`;
            } else {
              canvasCtx.fillStyle = `hsl(${(time / 20) % 255}, 50%, ${
                factor * 100 + 20
              }%)`;
            }
            canvasCtx.fillRect(x, height - barHeight, barWidth, barHeight);

            x += barWidth + barGap;
          }

          requestRef.current = requestAnimationFrame(draw);
        }
      };

    if (canvasRef.current) {
      const canvasCtx = canvasRef.current.getContext("2d");
      const width = canvasRef.current.width;
      const height = canvasRef.current.height;

      if (canvasCtx) {
        const analyser = makeAnalyzer({ stream, fftSize });
        const buffer = makeFreqBuffer(analyser);
        canvasCtx.clearRect(0, 0, width, height);

        requestRef.current = requestAnimationFrame(
          makeAnimateFn({
            analyser,
            buffer,
            canvasCtx,
            width,
            height,
          })
        );
      }
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [stream, bufferMax, fftSize, barGap, log, dark]);

  return (
    <canvas
      className="MiniAudioStreamViz"
      ref={canvasRef}
      style={style}
    ></canvas>
  );
};

export default MiniAudioStreamViz;
