import { useEffect, useState } from 'react';
import { DetectedMediaDevices, detectMediaDevices } from '../utils/detectMediaDevices'


export default function useDetectMediaDevices() {
  const [state, setState] = useState<DetectedMediaDevices>()
  useEffect(() => {
    (async () => {
      setState(await detectMediaDevices())
    })()
  }, [])
  
  return state
}
