import React, { useState, useEffect, useCallback, useMemo } from "react";
import { map } from "lodash";
import styled from "styled-components";
import Grid from "./Grid";
import Keyboard from "./Keyboard";

import { ClueParser } from "./clues";
import {
  AnswerChar,
  AnswerGrid,
  Circles,
  Clues,
  ClueStartGrid,
  ErrorGrid,
  GameAction,
  GameActionType,
  Size,
  StaticGameState,
} from "./types";
import {
  FocusDir,
  GLOBAL_FOCUS_IDENT,
  MutableGameState,
} from "../../appshared/types";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { makeGameReducer } from "./gameReducer";
import { htmlUnescape } from "../../utils/stringUtils";

const GameElem = styled.div``;

const KeyboardContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 3;
  // Safari on iOS without home button adds the little bottom line which
  // interferes with keyboard, so need bottom spacing
  // https://www.eventbrite.com/engineering/mobile-safari-why/
  // https://ux.stackexchange.com/questions/46103/ios-7-safari-and-footer-buttons#comment142125_46109
  /* padding-bottom: 69px; 
  @media (orientation: landscape) {
    padding-bottom: 45px;
  } */
`;

const ClueContainer = styled.div`
  background-color: #ddf;
  position: relative;
  min-height: 60px;
  box-sizing: border-box;
  text-align: center;
`;

const ClueText = styled.div`
  padding: 10px;
  padding-left: 12vw;
  padding-right: 12vw;
  color: black;
`;

const NextPrevClue = styled.div`
  background-color: #eef;
  border-radius: 3px;
  width: 10vw;
  height: 40px;
  border: 1px solid #555;
  box-sizing: border-box;
  padding-top: 3vw;
  text-align: center;
  position: absolute;
  left: 4px;
  top: 4px;
  cursor: pointer;
  color: black;

  &.right {
    left: auto;
    right: 4px;
  }
`;

export type GameProps = {
  clues: Clues;
  circles?: Circles | null;
  gameState: MutableGameState;
  size: Size;
  grid: AnswerGrid;
  gridnums: ClueStartGrid;
  showIncorrectInPuzzle?: boolean;
  showIncorrectInWord?: boolean;
  onGameStateChange: (gameState: MutableGameState) => void;
};



function Game({
  clues,
  size,
  grid,
  gridnums,
  circles,
  gameState,
  showIncorrectInPuzzle,
  showIncorrectInWord,
  onGameStateChange,
}: GameProps) {
  const [showVirtKeyboard, setShowVirtKeyboard] = useState(false);

  const turnOnKeyboard = useCallback(()=> {
    setShowVirtKeyboard(true);
  }, [setShowVirtKeyboard]);

  useEffect(() => {
    window.addEventListener("touchstart", turnOnKeyboard);
    return function tearDown() {
      window.removeEventListener("touchstart", turnOnKeyboard);
    }
  }, [turnOnKeyboard]);

  const focusIdent = GLOBAL_FOCUS_IDENT;

  const staticState = useMemo<StaticGameState>(() => ({
    size,
    answerGrid: grid,
    clues,
    clueStartGrid: gridnums,
  }), [size, grid, clues, gridnums])

  const parsedClues = useMemo<ClueParser>(() => new ClueParser(staticState), [staticState])

  // eslint-disable-next-line
  const gameReducer = useCallback(
    makeGameReducer({ parsedClues, staticState }),
    [staticState, parsedClues]
  );

  // Dont use useReducer, because we dont want the state it provides
  function dispatch(action: GameAction) {
    onGameStateChange(gameReducer(gameState, action));
  }

  const { entryGrid, focuses } = gameState;
  const dir = focuses?.[focusIdent]?.dir || FocusDir.Across;
  const index = focuses?.[focusIdent]?.index || 0;
  const clueNum = parsedClues.getClueNumAt({ dir, index })
  const clueText = parsedClues.getClueText({ clueNum, dir })

  const clueGrid = parsedClues.getClueGrid({ dir });

  const gameGrid = map(
    grid,
    (char, index) =>
      AnswerChar.Blank === char
        ? char // just pass through blank chars
        : entryGrid[index] || "" // otherwise display the current entryGrid character
  );

  let errors : ErrorGrid = [];
  
  function isWrong(char: AnswerChar, index: number) {
    return (
      !entryGrid[index] ?
        false 
      : AnswerChar.Blank === char ? 
        false // blank chars are never errors
      : 
        char !== entryGrid[index]
    )
  }

  if (showIncorrectInPuzzle) {
    errors = map(grid, isWrong)
  } else if (showIncorrectInWord) {
    errors = map(grid, (char, index)=> 
      clueGrid[index] === clueNum && isWrong(char, index)
    );
  }

  return (
    <GameElem>
      <Grid
        size={size}
        gridnums={gridnums}
        grid={gameGrid}
        focusDir={dir}
        focusIndex={index}
        errors={errors}
        circles={circles}
        onFocus={(index) => {
          dispatch({ type: GameActionType.FocusAt, focusIdent, index });
        }}
        // onRefocus={()=> dispatch({ type: GameActionType.FocusAt, focusIdent, index: focuses[ONLY_IDENT].index })}
        onGridIndexValueChange={(char, index) => {
          dispatch({ type: GameActionType.SetChar, focusIdent, char, index });
        }}
        onGridIndexClear={(index) =>
          dispatch({ type: GameActionType.ClearChar, focusIdent })
        }
        onUp={() => dispatch({ type: GameActionType.FocusUp, focusIdent })}
        onDown={() => dispatch({ type: GameActionType.FocusDown, focusIdent })}
        onLeft={() => dispatch({ type: GameActionType.FocusLeft, focusIdent })}
        onRight={() =>
          dispatch({ type: GameActionType.FocusRight, focusIdent })
        }
        onNext={() => dispatch({ type: GameActionType.ClueNext, focusIdent })}
        onPrev={() => dispatch({ type: GameActionType.CluePrev, focusIdent })}
      />

      <KeyboardContainer>
        {showVirtKeyboard && (
          <Keyboard
            onBackspaceEmit={() =>
              dispatch({ type: GameActionType.ClearChar, focusIdent })
            }
            onCharEmit={(char) =>
              dispatch({ type: GameActionType.SetChar, char, focusIdent })
            }
          />
        )}
        <ClueContainer>
          {clueNum !== 1 && (
            <NextPrevClue
              onClick={() =>
                dispatch({ type: GameActionType.CluePrev, focusIdent })
              }
            >
              <FaArrowLeft />
            </NextPrevClue>
          )}
          <ClueText>
            {
              clueNum !== 0 ? (
                <>
                  {clueNum}. {htmlUnescape(clueText)}
                </>
              ) : (
                "\u00A0"
              ) // &nbsp
            }
          </ClueText>

          <NextPrevClue
            className="right"
            onClick={() =>
              dispatch({ type: GameActionType.ClueNext, focusIdent })
            }
          >
            <FaArrowRight />
          </NextPrevClue>
        </ClueContainer>
      </KeyboardContainer>
    </GameElem>
  );
}

export default Game;
