import styled from "styled-components";

export const H1 = styled.div`
  font-size: 1.3rem;
`;

export const H2 = styled.div`
  font-size: 1.2rem;
`;

export const H3 = styled.div`
  font-size: 1.1rem;
`;
