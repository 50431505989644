import { FunctionComponent } from "react";
import { TextField, } from '@mui/material';
import { Controller, Control } from "react-hook-form";


type EmailFieldProps = {
  control: Control,
  name: string
}

const EmailField: FunctionComponent<EmailFieldProps> = ({ name, control }) => {
  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      rules={{
        required: "Email is required",
        pattern: /.+@.+/
      }}
      render={({ field, fieldState }) =>
        <TextField
          label="Email"
          {...field}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ""}
        />
      }
    />
  )
}

export default EmailField;