import React, { FunctionComponent } from "react";

import { GameItemApiType } from "../appshared/apiSchema";
import styled from "styled-components";
import { dateObjToStr, getDayOfWeek } from "../utils/date";

import { isNil, isNumber } from "lodash";

import blueBg from './gameItemBg2.png';


const GAME_EL_HEIGHT = 150;


interface GameElProps {
  completionFactor?: number|null;
}

function getElBgPos({ completionFactor }: GameElProps) {
  const val = (
    isNil(completionFactor) ?
      GAME_EL_HEIGHT
    :
      GAME_EL_HEIGHT - (GAME_EL_HEIGHT * completionFactor)
  )
  return val;
}

export const GameElWrapper = styled.div`
  background-color: white;
  display: inline-block;
  /* margin: 2%; */
  margin: 1%;
  width: 48%;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
`

export const GameEl = styled.button<GameElProps>`

  padding: 4px;
  border: 1px solid grey;
  height: ${GAME_EL_HEIGHT}px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  background-image: url(${blueBg}); //data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAnUlEQVR42u3RAQ0AAAgDIO1q/wq3hnNQga5JijNaiBCECEGIEIQIQYgQIUIQIgQhQhAiBCFCEIIQIQgRghAhCBGCEIQIQYgQhAhBiBCEIEQIQoQgRAhChCAEIUIQIgQhQhAiBCEIEYIQIQgRghAhCEGIEIQIQYgQhAhBCEKEIEQIQoQgRAhCECIEIUIQIgQhQhAiRIgQhAhBiBCEfLewXe6tPFMBjQAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  background-position: 0 ${(props)=> props ? getElBgPos(props): GAME_EL_HEIGHT}px;

  // TODO: Figure out why animation won't work
  /* & {
      animation: water-rise 1 1s ease-in-out;
  }

  @keyframes water-rise {
    from {
      background-position: 0 ${GAME_EL_HEIGHT}px;
    }
    to {
      background-position: 0 ${(props)=> props ? getElBgPos(props): GAME_EL_HEIGHT}px;
    }
  } */

`;

const GameTitleElem = styled.div`
  font-size: 1rem;
  color: white;
  mix-blend-mode: difference;
`

const GameSubElem = styled.div`
  font-size: 0.8rem;
  color: white;
  mix-blend-mode: difference;
  margin-top:7px;
`;



function percentFormat(num: number) {
  return `${Math.round(num * 100)}%`
}


type GameItemProps = {
  game: GameItemApiType
  completionFactor: number|null
  onChoose: ()=> void
};



const GameItem: FunctionComponent<GameItemProps> = ({ game, completionFactor, onChoose }) => {

  return (
    <GameElWrapper key={game.id}>
      <GameEl
        className="GameItem"
        completionFactor={completionFactor}
        onClick={() => onChoose()}
      >
        <GameTitleElem>
          NYTimes 
          <br/>
          {game.crosswordIdent}
          <br/>
          {((x) => (x ? x : ""))(
            getDayOfWeek(new Date(game.crosswordIdent))
          )}
        </GameTitleElem>
        <GameSubElem>
          {isNumber(completionFactor) ? `Completed ${percentFormat(completionFactor)}` : 'Loading...'}
        </GameSubElem>
        <GameSubElem>
          Created {dateObjToStr(new Date(game.createdAt))}
        </GameSubElem>
      </GameEl>
    </GameElWrapper>
  )
};

export default GameItem;