import { useEffect, useState } from "react";
import { SerializedCrossword } from "../components/crossword/types";
import stringify from 'fast-json-stable-stringify'

import { apiGet } from '../utils/api'

const getApiUri = (dateStr: string) =>
  `https://raw.githubusercontent.com/topher515/nyt_crosswords/master/${dateStr}.json`;

const getCacheKey = (dateStr: string) =>
  `nytimes-${dateStr}`

function fetchWithCache(dateStr: string) {
  const serializedData = localStorage.getItem(getCacheKey(dateStr))
  if (serializedData) {
    return JSON.parse(serializedData)
  }
  const promise = apiGet<SerializedCrossword>(getApiUri(dateStr))
  promise.then((data) => {
    localStorage.setItem(getCacheKey(dateStr), JSON.stringify(data))
  })
  return promise
}  

export default function useFetchCrosswords(dateStrs: Array<string>) {
  const [crosswords, setCrosswords] = useState<Array<SerializedCrossword>|null>(null);
  const [error, setError] = useState<Error>()

  useEffect(() => {
    (async () => {
      const promises = dateStrs.map((dateStr) => fetchWithCache(dateStr))
      try {
        setCrosswords(await Promise.all(promises))
      } catch (e) {
        setError(e)
      }
    })()
    // eslint-disable-next-line
  }, [stringify(dateStrs)])

  return {
    crosswords,
    error
  };
}
