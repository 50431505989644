import { useEffect, useState } from "react";
import { isChromeMobile } from "../utils/userAgent";

function isPermissionsError(err: Error) {
  return "NotAllowedError" === err.name;
}

function isChromeMobileError(err: Error) {
  return isPermissionsError(err) && isChromeMobile()
}

function useLocalAudioStream({ enabled }: {enabled: boolean}) { // props: UseLocalAudioStreamProps
  const [stream, setStream] = useState<MediaStream>();
  const [streamErr, setStreamErr] = useState<string>();

  useEffect(() => {
    (async () => {
      if (enabled && (!stream || !stream.active)) {
        try {
          setStream(await navigator.mediaDevices.getUserMedia({ audio: true }));
        } catch (err) {
          if (isChromeMobileError(err)) {
            setStreamErr('Chrome on iOS does not support audio streaming')
          
          } else if (isPermissionsError(err)) {
            setStreamErr('You must grant microphone permission')
          }
          setStreamErr(String(err));
        }
      }
    })();
    return function tearDown() {
      // TODO: Is this enoguh teardown / does it work at all?
      stream?.getAudioTracks()[0].stop();
    };
  }, [stream, enabled]);
    
  return {
    stream, error:streamErr
  }
}

export default useLocalAudioStream