
import { reduce, isNil } from "lodash";
import { MutableGameState } from "../../appshared/types";
import { SerializedCrossword, AnswerChar } from "./types";

/**
 * 
 * @returns The decimal between [0, 1] expressing how completely filled in the crossword is (but 
 * not if those answers are correct).
 */
export function calcCompletionFactor(crossword: SerializedCrossword, gamestate: MutableGameState): number {

    const answeredCount = countAnswered(gamestate)
    if (answeredCount === 0) {
        return 0
    }
    return answeredCount / countAnswerable(crossword);
}

export function countAnswerable(crossword: SerializedCrossword): number {
    return reduce(crossword.grid, (accu, value)=> accu + (value === AnswerChar.Blank ? 0 : 1), 0);
}

export function countAnswered(gamestate: MutableGameState): number {
    return reduce(gamestate.entryGrid, (accu, value)=> accu + (isNil(value) ? 0 : 1), 0)
}