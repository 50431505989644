import React from "react";
import PropTypes from "prop-types";

import { includes, noop } from "lodash";
import { FaRegCircle } from "react-icons/fa";
import styled, { css } from "styled-components";

import { BLANK_BOX_CHAR } from "./const.js";

const [LEFT, UP, RIGHT, DOWN] = [37, 38, 39, 40];
const BACKSPACE = 8;

const ALPHABET = "acbdefghijklmnopqrstuvwxyz";

const CircleBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  font-size: 23px;
  color: #aaa;
`;

const BoxElem = styled.div`
  border: 1px solid #111;
  border-left: none;
  ${(props) =>
    !props.isTop &&
    css`
      border-top: none;
    `}
  position: relative;
  padding: 0;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 1vw;

  ${(props) =>
    props.size &&
    props.size.cols &&
    css`
      font-size: ${100 / props.size.cols / 2}vw;
      height: ${100 / props.size.cols}vw;
      width: ${100 / props.size.cols}vw;
    `}

  ${(props) =>
    props.char &&
    css`
      background-color: ${props.char === BLANK_BOX_CHAR ? "black" : "white"};
    `}
  
  ${(props) => css`
    background-color: ${props.char === BLANK_BOX_CHAR
      ? "black"
      : props.isFocused
      ? props.hasError
        ? "#CDE"
        : "#57F"
      : props.isPartOfSelectedClue
      ? props.hasError
        ? "#EDE"
        : "#DDF"
      : props.hasError
      ? "#FDD"
      : props.isCorrect
      ? "#DFD"
      : "white"};
  `}

  &:focus {
    outline: 1px solid blue;
    box-shadow: none;
  }
`;

const NumElem = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  cursor: default;
  color: #aaa;
  z-index: 2;
  font-size: 2.1vw;
`;

export const getBoxId = (index) => `box-${index}`;

function Box({
  index,
  size,
  isPartOfSelectedClue,
  isFocused,
  hasCircle,
  isCorrect,
  hasError,
  char,
  num,
  onFocus,
  onLeft,
  onRight,
  onUp,
  onDown,
  onCharChange,
  onCharClear,
  onClick,
  onMouseDown,
}) {
  return (
    <BoxElem
      id={getBoxId(index)}
      isTop={index < size.cols}
      size={size}
      char={char}
      hasError={hasError}
      num={num}
      isPartOfSelectedClue={isPartOfSelectedClue}
      isFocused={isFocused}
      tabIndex={char === BLANK_BOX_CHAR ? undefined : index}
      onFocus={
        char === BLANK_BOX_CHAR
          ? noop
          : (e) => {
              if (!isFocused) {
                onFocus(e);
              }
            }
      }
      onKeyDown={(e) => {
        if (includes(ALPHABET, e.key)) {
          onCharChange(e.key.toUpperCase());
          // onNext();
        } else if (e.keyCode === LEFT) {
          onLeft();
        } else if (e.keyCode === UP) {
          onUp();
        } else if (e.keyCode === DOWN) {
          onDown();
        } else if (e.keyCode === RIGHT) {
          onRight();
        } else if (e.keyCode === BACKSPACE) {
          onCharClear();
          // onPrev();
        }
      }}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {char !== BLANK_BOX_CHAR && num !== 0 && <NumElem>{num}</NumElem>}
      {char !== BLANK_BOX_CHAR && char}

      {hasCircle && (
        // <svg
        //   height="100"
        //   width="100"
        //   style={{position:'absolute', left:-5, top:-5, width:'100%', height:'100%'}}
        // >
        //   <circle cx="50" cy="50" r="40" stroke="black" stroke-width="1" fill="transparent" />
        // </svg>
        <CircleBox>
          <FaRegCircle />
        </CircleBox>
      )}
    </BoxElem>
  );
}

Box.propTypes = {
  index: PropTypes.number.isRequired,
  size: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    cols: PropTypes.number.isRequired,
  }).isRequired,
  isPartOfSelectedClue: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  char: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  onFocus: PropTypes.func.isRequired,
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,
  onUp: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onCharChange: PropTypes.func.isRequired,
  onCharClear: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func,
};

export default Box;
