import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { Box, FormHelperText, Button } from '@mui/material';
import { GrSend } from 'react-icons/gr'

import { SendClaimEmailBodyType, URL_SEND_CLAIM_EMAIL } from "../appshared/apiSchema";
import { apiPost } from "../utils/puzzliveApi";
import EmailField from "./formFields/EmailField";
import { DisplayableError } from "../utils/api";


type SendClaimEmailFormProps = {
  onSent: (email: string) => void
  jwtToken: string
}

const SendClaimEmailForm: FunctionComponent<SendClaimEmailFormProps> = ({ jwtToken, onSent }) => {

  const {
    control,
    formState,
    handleSubmit,
    setError,
  } = useForm();

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await apiPost<null, SendClaimEmailBodyType>(URL_SEND_CLAIM_EMAIL, { email, currentUrl: window.location.toString() }, { jwtToken })
      onSent(email)
    } catch (err) {
      if (err instanceof DisplayableError) {
        setError("submit", { type: "api", message: err.message })
      } else {
        throw err
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Box>
        <EmailField name="email" control={control} />

        <FormHelperText error={!!formState.errors.submit}>
          {formState.errors.submit?.message}
        </FormHelperText>

        <Button type="submit" startIcon={<GrSend />}>Submit</Button>
      </Box>

    </form>
  )
}

export default SendClaimEmailForm;