import { MutableGameState, RoomIdent, RoomState, GameId } from "./types";

export const UPDATE_ROOM_STATE_EVENT = "room-state";
export const JOIN_ROOM_EVENT = "join-room";
export const LEAVE_ROOM_EVENT = "leave-room";
export const START_SESSION_EVENT = "start-session";
export const CONTINUE_SESSION_EVENT = "continue-session";
export const SESSION_OK_EVENT = "session-ok";
export const UPDATE_GAME_STATE_EVENT = "update-game-state";
export const CHOOSE_GAME_EVENT = "choose-game";
export const EXIT_GAME_EVENT = "exit-game";
export const OH_NO_RESET_STATE_EVENT = "ohno-reset-state";
export const AUDIO_READY ="audio-ready"

export type AudioReadyEventData = {
  roomIdent: string
}

export type JoinRoomEventData = {
  roomIdent: string;
};

export type LeaveRoomEventData = {
  roomIdent: string;
};

export type MadeRoomEventData = {
  roomIdent: string;
  roomId: string;
};
export type StartSessionEventData = {
  userIdent: number;
  password: string;
};

export type ContinueSessionEventData = {
  jwtToken: string;
};
export type StartedSessionEventData = {
  jwtToken: string;
};

export type UpdateGameStateEventData = {
  gameState: MutableGameState;
  roomIdent: RoomIdent;
};

export type UpdateRoomStateEventData = RoomState;

export type AuthJwtToken = {
  sub: string;
  exp: number;
};

export type ChooseGameEventData = {
  roomIdent: RoomIdent;
  gameId: GameId;
};

export type ExitGameEventData = {
  roomIdent: RoomIdent;
};
