import { FocusDir, FocusIdent, MutableGameState } from "../../appshared/types";

export enum AnswerChar {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  M = "M",
  N = "N",
  O = "O",
  P = "P",
  Q = "Q",
  R = "R",
  S = "S",
  T = "T",
  U = "U",
  V = "V",
  W = "W",
  X = "X",
  Y = "Y",
  Z = "Z",
  Blank = ".",
}

export enum Circle {
  No = 0,
  Yes = 1,
}

export type ErrorGrid = Array<boolean>;
export type Circles = Array<Circle>;
export type ClueNum = number;
export type ClueText = string;
export type NumPrefixedClueText = string;
export type GenericGrid<T> = Array<T>;
export type AnswerGrid = GenericGrid<AnswerChar>;
export type ClueStartGrid = GenericGrid<ClueNum>;
export type ClueDirOwnerGrid = GenericGrid<ClueNum>;
export type ClueNumToClueTextMap = {
  // clueNum is ClueNum [ts(1336)]
  [clueNum: number]: ClueText;
};
export type ParsedClues = {
  textByNum: ClueNumToClueTextMap
  nums: Array<number>
  next: { [num: number]: number }
  prev: { [num: number]: number }
}
export type Size = {
  cols: number;
  rows: number;
};

export type ClueNumAndDir = {
  clueNum: ClueNum,
  dir: FocusDir
}

export type IndexAndDir = {
  index: number,
  dir: FocusDir
}

export type Clues = {
  across: Array<NumPrefixedClueText>;
  down: Array<NumPrefixedClueText>;
};

export type SerializedCrossword = {
  grid: AnswerGrid,
  gridnums: ClueStartGrid,
  clues: {
    across: Array<string>,
    down: Array<string>
  },
  circles: Array<number>
  size: Size
}

export type StaticGameState = {
  size: Size;
  clueStartGrid: ClueStartGrid;
  answerGrid: AnswerGrid;
  clues: Clues;
};

export type TotalGameState = MutableGameState & StaticGameState;

export enum GameActionType {
  SetChar = "SET_CHAR",
  ClearChar = "CLEAR_CHAR",
  UpdateGameState = "UPDATE_GAME_STATE",
  FocusRight = "FOCUS_RIGHT",
  FocusLeft = "FOCUS_LEFT",
  FocusUp = "FOCUS_UP",
  FocusDown = "FOCUS_DOWN",
  FocusAt = "FOCUS_AT",
  ClueNext = "CLUE_NEXT",
  CluePrev = "CLUE_PREV",
}

export type GameActionWithChar = {
  type: GameActionType.SetChar;
  focusIdent: FocusIdent;
  char: string;
  index?: number;
};

export type GameActionWithIndex = {
  type: GameActionType.FocusAt;
  focusIdent: FocusIdent;
  index: number;
};

export type GameActionNoData = {
  type:
    | GameActionType.ClearChar
    | GameActionType.FocusRight
    | GameActionType.FocusLeft
    | GameActionType.FocusDown
    | GameActionType.FocusUp
    | GameActionType.ClueNext
    | GameActionType.CluePrev;
  focusIdent: FocusIdent;
};

export type GameAction =
  | GameActionWithChar
  | GameActionNoData
  | GameActionWithIndex;
