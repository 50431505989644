export function formatZeroPad(num: number): string {
  return String(num).padStart(2, "0");
}

export function htmlUnescape(input: string): string {
  const doc = new DOMParser().parseFromString(input, "text/html");
  const content = doc.documentElement.textContent;
  if (!content) {
    throw new Error("No valid string parsed")
  }
  return content;
}