import React, { FunctionComponent } from "react";

import styled from "styled-components";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import {
  INITIAL_CROSSWORD_DATE,
  LEAST_RECENT_CROSSWORD_DATE,
  MOST_RECENT_CROSSWORD_DATE,
} from "../const";

import { dateObjToStr } from "../utils/date";
import availableCrosswords from "../const/availableCrosswords";

const DateChooserElem = styled.div`
  background-color: none;

  .react-calendar {
    width: 100%;
  }
`;

type CrosswordDateChooserProps = {
  onDateChoose: (dateStr: string) => void;
};

const CrosswordDateChooser: FunctionComponent<CrosswordDateChooserProps> = ({
  onDateChoose,
}) => {
  return (
    <DateChooserElem>
      <Calendar
        calendarType="US"
        maxDate={MOST_RECENT_CROSSWORD_DATE}
        minDate={LEAST_RECENT_CROSSWORD_DATE}
        value={INITIAL_CROSSWORD_DATE}
        onChange={(date: Date) => onDateChoose(dateObjToStr(date))}
        tileDisabled={({ date }) => {
          const dateStr = dateObjToStr(date);
          return !availableCrosswords.has(dateStr);
        }}
      />
    </DateChooserElem>
  );
};

export default CrosswordDateChooser;
