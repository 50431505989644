import { ClueParser } from "./clues";
import {
  convertToAcrossGridIndex,
  convertToDownGridIndex,
} from "./gridLib";
import { GameAction, GameActionType, IndexAndDir, StaticGameState } from "./types";
import { MutableGameState, FocusDir } from "../../appshared/types";
import { find } from "lodash";


export function makeGameReducer({ parsedClues: clueParser, staticState }: { parsedClues: ClueParser, staticState: StaticGameState }) {
 
  const size = staticState.size
  const answerGrid = staticState.answerGrid

  return function gameReducer(
    state: MutableGameState,
    action: GameAction
  ): MutableGameState {
    const { focuses, entryGrid } = state;
    const focusIdent = action.focusIdent;
    const curDir = focuses?.[focusIdent]?.dir || FocusDir.Across;
    const curIndex = focuses?.[focusIdent]?.index || 0;
    const curIndexAsDown = convertToDownGridIndex(curIndex, size);
    const curChar = entryGrid[curIndex];
    const curClueNum =  clueParser.getClueNumAt({ dir: curDir, index: curIndex })

    function getNextClueBestIndexAndDir(): IndexAndDir {
      const { clueNum, dir } = clueParser.getNextClueNumDir({ clueNum: curClueNum, dir: curDir })

      const clueIndices = clueParser.getClueIndices({ clueNum, dir })
      const blankIndex = find(clueIndices, (i) => !entryGrid[i])
      if (blankIndex === undefined) {
        return { index: clueIndices[0], dir } // If they're all filled in, just return the first
      } else {
        return { index: blankIndex, dir }
      }
    }

    function getPrevClueBestIndexAndDir(): IndexAndDir {
      const { clueNum, dir } = clueParser.getPrevClueNumDir({ clueNum: curClueNum, dir: curDir })
      const clueIndices = clueParser.getClueIndices({ clueNum, dir })
      clueIndices.reverse()
      const blankIndex = find(clueIndices, (i) => !entryGrid[i])
      if (blankIndex === undefined) {
        return { index: clueIndices[0], dir } // If they're all filled in, just return the last index
      } else {
        return { index: blankIndex, dir }
      }
    }

    /**
     * @returns the next natural index which is the index for the box which should be selected
     * after completing the current box. Usually this is +1, but we also
     */
    function calcNextNaturalIndexAndDir(): IndexAndDir  {
      const newIndex =
        curDir === FocusDir.Across
          ? curIndex + 1
          : convertToAcrossGridIndex(curIndexAsDown + 1, size);
      if (curClueNum !== clueParser.getClueNumAt({ dir: curDir, index: newIndex })) {
        // debugger
        return getNextClueBestIndexAndDir();
      }
      return { index: newIndex, dir: curDir }
    }

    function calcPrevNaturalIndexAndDir(): IndexAndDir {
      const newIndex =
        curDir === FocusDir.Across
          ? curIndex - 1
          : convertToAcrossGridIndex(curIndexAsDown - 1, size);
      if (curClueNum !== clueParser.getClueNumAt({ dir: curDir, index: newIndex })) {
        return getPrevClueBestIndexAndDir();
      }
      return { index: newIndex, dir: curDir }
    }

    switch (action.type) {
      case GameActionType.SetChar:
        if (action.char.length !== 1) {
          throw new Error(`Cannot set focused char to ${action.char}`);
        }

        return {
          ...state,
          entryGrid: {
            ...entryGrid,
            // If the action supplies an index use that, otherwise use current index
            // (its possible this will yield odd edge cases...
            [action.index ? action.index : curIndex]: action.char,
          },
          focuses: {
            ...focuses,
            [action.focusIdent]: calcNextNaturalIndexAndDir(),
          },
        };

      case GameActionType.ClearChar:
        const prevNatIndexAndDir = calcPrevNaturalIndexAndDir();
        return curChar // Clear the char, don't move
          ? {
              ...state,
              entryGrid: {
                ...entryGrid,
                [curIndex]: null,
              },
            }
          : {
              ...state,
              focuses: {
                ...focuses,
                [action.focusIdent]: { dir: prevNatIndexAndDir.dir, index: prevNatIndexAndDir.index },
              },
              entryGrid: {
                ...entryGrid,
                [prevNatIndexAndDir.index]: null,
              },
            };

      case GameActionType.FocusAt:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]: {
              dir:
                action.index === curIndex
                  ? // Tapping on the same box switches directions
                    curDir === FocusDir.Across
                    ? FocusDir.Down
                    : FocusDir.Across
                  : curDir,
              index: action.index,
            },
          },
        };

      case GameActionType.ClueNext:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]: getNextClueBestIndexAndDir(),
          },
        };

      case GameActionType.CluePrev:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]: getPrevClueBestIndexAndDir(),
          },
        };

      case GameActionType.FocusLeft:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]:
              curIndex === 0
                ? { dir: curDir, index: curIndex } // noop
                : curDir === FocusDir.Down
                ? { dir: FocusDir.Across, index: curIndex }
                : { dir: curDir, index: curIndex - 1 },
          },
        };

      case GameActionType.FocusRight:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]:
              curIndex + 1 === answerGrid.length
                ? { dir: curDir, index: curIndex } // noop
                : curDir === FocusDir.Down
                ? { dir: FocusDir.Across, index: curIndex }
                : { dir: curDir, index: curIndex + 1 },
          },
        };

      case GameActionType.FocusUp:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]:
              curIndexAsDown === 0
                ? { dir: curDir, index: curIndex } // noop
                : curDir === FocusDir.Across
                ? { dir: FocusDir.Down, index: curIndex }
                : {
                    dir: curDir,
                    index: convertToAcrossGridIndex(curIndexAsDown - 1, size),
                  },
          },
        };

      case GameActionType.FocusDown:
        return {
          ...state,
          focuses: {
            ...focuses,
            [action.focusIdent]:
              curIndexAsDown + 1 === answerGrid.length
                ? { dir: curDir, index: curIndex } // noop
                : curDir === FocusDir.Across
                ? { dir: FocusDir.Down, index: curIndex }
                : {
                    dir: curDir,
                    index: convertToAcrossGridIndex(curIndexAsDown + 1, size),
                  },
          },
        };

      default:
        throw new Error(`Unexpected action`);
    }
  };
}
