import React from "react";

import styled from "styled-components";

import Game from "./crossword/Game";

import useFetchCrosswords from "../hooks/useFetchCrosswords";
import { MutableGameState } from "../appshared/types";

const GameElem = styled.div`
  background-color: none;
  position: relative;
`;
const ConnectErrorBox = styled.div`
  background-color: #fde6e6;
  border: 1px solid #330d0d;
  color: #330d0d;
  border-radius: 3px;
  padding: 4px;
  font-size: 1rem;
  position: absolute;
  left: 0;
  bottom: -2rem;
`;

type GameManagerProps = {
  connectError?: string | null;
  dateStr: string;
  gameState: MutableGameState;
  showIncorrectInWord?: boolean,
  showIncorrectInPuzzle?: boolean,
  onGameStateChange: (gameState: MutableGameState) => void;
};

function GameManager({
  dateStr,
  gameState,
  connectError,
  showIncorrectInWord,
  showIncorrectInPuzzle,
  onGameStateChange,
}: GameManagerProps) {
  const { crosswords, error } = useFetchCrosswords([dateStr]);

  return (
    <GameElem className="GameManager">
      {crosswords === null 
        ? "Loading" 
        : error 
        ? (
          String(error)
        ) : crosswords.length ? (
        <Game
          {...crosswords[0]}
          showIncorrectInWord={showIncorrectInWord}
          showIncorrectInPuzzle={showIncorrectInPuzzle}
          gameState={gameState}
          onGameStateChange={onGameStateChange}
        />
      ) : (
        "Loading..."
      )}
      {connectError && <ConnectErrorBox>{connectError}</ConnectErrorBox>}
    </GameElem>
  );
}

export default GameManager;
