import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { API_URL_BASE } from "../const";

type UseConnectedSocketProps = {
  jwtToken: string;
};

export default function useConnectedSocket({
  jwtToken,
}: UseConnectedSocketProps) {
  const [connectedSocket, setConnectedSocket] = useState<Socket | null>(null);
  const [connectError, setConnectError] = useState<string | null>(null);

  const socketRef = useRef<Socket>(
    io(API_URL_BASE, {
      autoConnect: false,
      auth: { token: `Bearer ${jwtToken}` },
    })
  );

  useEffect(() => {
    const sock = socketRef.current;

    sock.on("connect", () => {
      console.log("Socket connected");
      setConnectedSocket(sock);
      setConnectError(null);
    });

    sock.on("connect_error", (error) => {
      console.log("socket connect error", error);
      if (error.message === "xhr poll error") {
        setConnectError("Reconnecting to server—not saved");
      } else {
        setConnectError(error.toString());
      }
    });

    sock.connect();
  }, [socketRef, setConnectedSocket]);

  return {
    socket: connectedSocket,
    connectError,
  };
}
