import { useMemo } from "react";
import { buildClueGridAcross, buildClueGridDown } from "./gridLib";
import { AnswerGrid, ClueStartGrid, Size } from "./types";
import { FocusDir } from "../../appshared/types";

const useClueNumGridByDir = ({
  grid,
  gridnums,
  size,
}: {
  grid: AnswerGrid;
  gridnums: ClueStartGrid;
  size: Size;
}) => {
  const clueNumGridByDir = useMemo(
    () => ({
      [FocusDir.Across]: buildClueGridAcross({ grid, gridnums, size }),
      [FocusDir.Down]: buildClueGridDown({ grid, gridnums, size }),
    }),
    [grid, gridnums, size]
  );
  return clueNumGridByDir;
};

export default useClueNumGridByDir;
