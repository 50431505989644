import React, { FunctionComponent, useState } from "react";
import { HiOutlinePuzzle } from "react-icons/hi";
import { Box } from '@mui/material'

import styled from "styled-components";
import LoginChooseUserNameForm from "./LoginChooseUsernameForm";
import SendLoginEmailForm from "./SendLoginEmailForm";
import { H2 } from "./Headers";


const LoginBox = styled.div`
  text-align: center;
`;

const HeroTitle = styled.div`
  font-size: 3rem;
  padding: 2rem 2rem 0 2rem;
  z-index: 10;
  position: relative;
`;
const LoginContent = styled.div`
  z-index: 100;
  position: relative;
  margin-top: 20px;

  & input[type="text"] {
    margin-bottom: 10px;
  }
`;

const TitleText = styled.div`
  z-index: 2;
`;

const LogoEl = styled.div`
  font-size: 3rem;
  color: #525968;
  z-index: 1;
  position: relative;

  @media (prefers-reduced-motion: no-preference) {
    & {
      animation: app-logo-spin infinite 20s linear;
    }
  }

  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg) scale(1);
      color: #dd9c99;
    }
    50% {
      transform: rotate(180deg) scale(10);
      color: #001f3f;
    }
    to {
      transform: rotate(360deg) scale(1);
      color: #dd9c99;
    }
  }
`;

type LoginProps = {
  onTokenReceive: (jwtToken: string) => void;
};


const Login: FunctionComponent<LoginProps> = ({ onTokenReceive }) => {

  const [hasSentLoginEmail, setHasSentLoginEmail] = useState(false);

  return (
    <LoginBox className="Login">
      <Box sx={{ overflow: 'hidden' }}>
        <HeroTitle>
          <TitleText>puzz.live</TitleText>
        </HeroTitle>
        <LogoEl>
          <HiOutlinePuzzle style={{ verticalAlign: "bottom" }} />
        </LogoEl>
      </Box>

      <LoginContent>
        <Box>
          <Box>
            <H2 style={{ marginBottom: 50 }}>Tag team crossword puzzles.</H2>
          </Box>
          <Box>
            <p>Just start a game</p>
            <LoginChooseUserNameForm onTokenReceive={onTokenReceive} />
          </Box>
        </Box>
        <Box sx={{ height: 65 }}> &nbsp;</Box>
        <Box sx={{ height: 55 }}>— OR —</Box>
        <Box>
          <Box>
            <p>Login with an email address</p>
          </Box>
          <Box>
            {hasSentLoginEmail ?
              <>Check your email for login link</>
              :
              <SendLoginEmailForm onSent={() => setHasSentLoginEmail(true)} />
            }
          </Box>

        </Box>

      </LoginContent >
    </LoginBox >
  );
};

export default Login;
