import { useEffect, useMemo, useState } from "react";
import { CallRoute, UserId, UserMeta } from "../appshared/types";
import { reduce, map } from "lodash";
import stringify from "fast-json-stable-stringify";

import PeerKeeper from "../utils/peerkeeper";

type PeerStream = { userId: UserId; stream: MediaStream };

type UsePeerStreamsProps = {
  user: UserMeta
  stream?: MediaStream
  users: Array<UserMeta>;
  callRoutes: Array<CallRoute>;
};

export default function usePeerStreams({
  user,
  stream,
  users,
  callRoutes,
}: UsePeerStreamsProps): { peerStreams: Array<PeerStream> } {

  const [peerKeeper, setPeerKeeper] = useState<PeerKeeper>();
  const userIdByCallerId: { [callerId: string]: UserId } = useMemo(
    () =>
      reduce(
        users,
        (accum, { userId, callerPeerId }) => (!callerPeerId ? accum : {
          ...accum,
          [callerPeerId]: userId,
        }),
        {}
      ),
    [users]
  );

  useEffect(() => {
    if (stream && user.callerPeerId) {
      setPeerKeeper(
        new PeerKeeper({ id: user.callerPeerId, debug: 2, stream })
      );
    }
    
  }, [user.callerPeerId, stream]);

  const [peerStreams, setPeerStreams] = useState<Array<PeerStream>>([]);

  useEffect(() => {
    if (peerKeeper) {
      peerKeeper.onPeerStreamsChange((ps: { [peerId: string]: MediaStream }) => {
      const peerStreams = map(ps, (stream, peerCallerId) => {
        return { userId: userIdByCallerId[peerCallerId], stream };
      });
      setPeerStreams(peerStreams);
    });
    }
    
    return function tearDown() {
      if (peerKeeper) {
        peerKeeper.onPeerStreamsChange(null);
      }
    };
    // eslint-disable-next-line
  }, [peerKeeper, stringify(userIdByCallerId)]);

  useEffect(() => {
    if (peerKeeper) {
      peerKeeper.ensureConnections(callRoutes);
    }
    // eslint-disable-next-line
  }, [peerKeeper, stringify(callRoutes)]);

  return { peerStreams };
}
