import { keys, reduce, sortBy } from "lodash";
import { FocusDir } from "../../appshared/types";
import { buildClueGridAcross, buildClueGridDown, buildStartIndexByClueNum, convertToAcrossGrid, convertToAcrossGridIndex, convertToDownGridIndex, otherDir } from "./gridLib";
import {
  ClueDirOwnerGrid,
  ClueNum,
  Clues,
  ClueText,
  NumPrefixedClueText,
  ParsedClues,
  Size,
  StaticGameState,
  ClueNumAndDir
} from "./types";





const parseRawClueText = (
  rawClueText: string
): { clueNum: ClueNum; clueText: ClueText } => {
  const match = rawClueText.match(/(?<clueNum>\d+)\.\s(?<clueText>.+)/);
  if (!match || !match.groups) {
    throw new Error(`Clue in unexpected format: "${rawClueText}"`);
  }
  return {
    clueNum: parseInt(match.groups.clueNum),
    clueText: match.groups.clueText,
  };
};

function parseClues({ across, down }: Clues): { across: ParsedClues, down: ParsedClues } {

  const parseRawClueList = (
    clueItems: Array<NumPrefixedClueText>
  ): ParsedClues => {

    const textByNum = reduce(
      clueItems,
      (acc, rawClueText) => {
        const { clueNum, clueText } = parseRawClueText(rawClueText);
        return { ...acc, [clueNum]: clueText };
      },
      {}
    )
    const clueNums = sortBy(keys(textByNum).map(num => parseInt(num)))

    const prev: {[num: number]: number} = {}
    const next: {[num: number]: number} = {}
    for (var i = 0; i < clueNums.length - 1; i++) {
      next[clueNums[i]] = clueNums[i + 1]
      prev[clueNums[i+1]] = clueNums[i]
    }

    return {
      textByNum,
      nums: clueNums,
      next,
      prev
    }
  }
    
  return {
    across: parseRawClueList(across),
    down: parseRawClueList(down),
  };
}

enum NextOrPrev {
  next = "next",
  prev = "prev"
}

export class ClueParser {

  size: Size
  clueGrid: {
    [FocusDir.Across]: ClueDirOwnerGrid,
    [FocusDir.Down]: ClueDirOwnerGrid,
  };
  clueStartIndexByClueNum: { [clueNum: number]: number };
  parsedClues: {
    [FocusDir.Across]: ParsedClues,
    [FocusDir.Down]: ParsedClues
  };

  constructor({ size, clues, clueStartGrid, answerGrid }: StaticGameState) {
    this.size = size
    this.clueGrid = {
      [FocusDir.Across]: buildClueGridAcross({
        grid: answerGrid,
        gridnums: clueStartGrid,
        size,
      }),
      [FocusDir.Down]: buildClueGridDown({
        grid: answerGrid,
        gridnums: clueStartGrid,
        size,
      }),
    }
    this.clueStartIndexByClueNum = buildStartIndexByClueNum({
      gridnums: clueStartGrid,
    });
    this.parsedClues = parseClues(clues);
  }

  getClueIndices({ clueNum, dir }: ClueNumAndDir): Array<number> {
    const startIndex = this.clueStartIndexByClueNum[clueNum]
    const indices = []
    if (dir === FocusDir.Across) {
      for (let i = 0; this.clueGrid[dir][startIndex + i] === clueNum; i+=1) {
        indices.push(startIndex + i)
      }
    } else if (dir === FocusDir.Down) {
      const downStartIndex = convertToDownGridIndex(startIndex, this.size)
      const clueGrid = convertToAcrossGrid(this.clueGrid[dir], this.size)
      for (let i = 0; clueGrid[downStartIndex + i] === clueNum; i+=1) {
        indices.push(convertToAcrossGridIndex(downStartIndex + i, this.size))
      }
    }

    return indices
  }

  getClueGrid({ dir }: { dir: FocusDir }): ClueDirOwnerGrid {
    return this.clueGrid[dir]
  }

  _getClueNumDir(nextOrPrev: NextOrPrev, { dir, clueNum }: ClueNumAndDir): ClueNumAndDir {
    const newClueNum = this.parsedClues[dir][nextOrPrev][clueNum]
    if (newClueNum) {
      return {
        clueNum: newClueNum,
        dir: dir
      }
    }
    return {
      clueNum: this.parsedClues[otherDir(dir)].nums[0],
      dir: otherDir(dir)
    }
  }

  getClueNumAt({ dir, index }: { dir:FocusDir, index:number }) {
    return this.clueGrid[dir][index]
  }

  getNextClueNumDir(args: ClueNumAndDir): ClueNumAndDir {
    return this._getClueNumDir(NextOrPrev.next, args)
  }

  getPrevClueNumDir(args: ClueNumAndDir): ClueNumAndDir {
    return this._getClueNumDir(NextOrPrev.prev, args)
  }

  getClueText({ dir, clueNum }: ClueNumAndDir): string {
    return this.parsedClues[dir].textByNum[clueNum]
  }
}
