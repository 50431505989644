import Peer, { MediaConnection } from "peerjs";
import { forEach } from "lodash";

// Ref this: https://github.com/nwah/peerjs-audio-chat

// const NETWORK_PEER_ID_PREFIX = 'testfoolongprefixcox-'
function toNetworkPeerId(id: string) {
  return id; //.replace(/-/g,'').slice(20)
  // return `${NETWORK_PEER_ID_PREFIX}${id}`
}
function fromNetworkPeerId(networkPeerId: string) {
  return networkPeerId; //.replace(/-/g,'').slice(20)
  // return networkPeerId.slice(NETWORK_PEER_ID_PREFIX.length)
}

const logDebug = (...args: any[]) => console.log(...args);

type PeerId = string;

type PeerKeeperOpts = {
  id: PeerId;
  stream: MediaStream;
  onPeerStreamsChange?: null | ((peerStreams: PeerStreams) => void);
  debug?: number;
};

type PeerStreams = { [peerId: string]: MediaStream };

export default class PeerKeeper {
  peerClient: Peer;
  currentCalls: {
    [peerId: string]: { call: MediaConnection; stream: MediaStream | null };
  };
  localStream: MediaStream;
  onPeerStreamsChangeCallback:
    | null
    | undefined
    | ((peerStreams: PeerStreams) => void);

  constructor({
    id,
    stream,
    debug = 0,
    onPeerStreamsChange = undefined,
  }: PeerKeeperOpts) {
    logDebug("Initializing peer client", { id });
    this.peerClient = new Peer(toNetworkPeerId(id), { debug });

    this.currentCalls = {};
    this.localStream = stream;
    this.onPeerStreamsChangeCallback = onPeerStreamsChange;

    this.peerClient.on("call", (call: MediaConnection) => {
      this._handleIncomingCall(call);
    });
  }

  onPeerStreamsChange(func?: null | ((peerStreams: PeerStreams) => void)) {
    this.onPeerStreamsChangeCallback = func;
  }

  _setupCallHandlers(call: MediaConnection) {
    call.on("stream", (remoteStream: MediaStream) => {
      logDebug("Got remote stream", { call });
      this.currentCalls[fromNetworkPeerId(call.peer)].stream = remoteStream;
      // calls change occurs
      if (this.onPeerStreamsChangeCallback) {
        this.onPeerStreamsChangeCallback(this.getCurrentPeerStreams());
      }
    });
    call.on("close", () => {
      logDebug("Got close call", { call });
      delete this.currentCalls[fromNetworkPeerId(call.peer)];
      // calls change occurs
    });
  }

  _handleIncomingCall(call: MediaConnection) {
    logDebug("Got incoming call", { call });
    this._setupCallHandlers(call);
    this.currentCalls[fromNetworkPeerId(call.peer)] = { call, stream: null };
    // calls change occurs
    call.answer(this.localStream);
  }

  ensureConnections(peerCallRoutes: Array<[PeerId, PeerId]>) {
    if (!this.peerClient.id) {
      console.warn("Weird, peerClient has no id");
    }

    if (this.peerClient.destroyed) {
      // throw new Error("Cannot start calls when peer is already destroyed")
      console.warn("cannot start calls when peer is already destroyed");
    }

    logDebug("New peer call routes", {
      id: this.peerClient.id,
      peerCallRoutes,
    });
    // Determine the set of all peerIds which should have calls going
    // not including *this* peerId
    const desiredPeerIdSet = new Set(peerCallRoutes.flatMap((i) => i));
    desiredPeerIdSet.delete(this.peerClient.id);

    // Close unwanted calls
    // TODO: Determine if all clients trying to close the call is bad!
    forEach(this.currentCalls, ({ call, stream }, currentPeerId) => {
      if (!desiredPeerIdSet.has(currentPeerId)) {
        logDebug("closing unwanted call to", { peerId: currentPeerId });
        call.close();
        delete this.currentCalls[currentPeerId];
        // Calls change occurs
      }
    });

    // Start wanted calls
    peerCallRoutes.forEach(([fromPeerId, toPeerId]) => {
      logDebug();
      if (this.peerClient.id === fromPeerId) {
        // this is our client, so we need to initiate calls
        if (!this.currentCalls[toPeerId]) {
          const call = this.peerClient.call(
            toNetworkPeerId(toPeerId),
            this.localStream
          );
          logDebug("Started outgoing call", { call });
          this.currentCalls[toPeerId] = { call, stream: null };
          // calls change occurs
          this._setupCallHandlers(call);
        }
      }
    });
  }

  close(): void {
    forEach(this.currentCalls, ({ call }) => {
      call.close();
    });
    console.log("Closing/destroying peerkeeper with client id", {
      id: this.peerClient.id,
    });
    this.peerClient.destroy();
  }

  getCurrentPeerStreams(): PeerStreams {
    const streams: PeerStreams = {};
    forEach(this.currentCalls, ({ call, stream }, peerId) => {
      if (stream) {
        streams[peerId] = stream;
      }
    });
    return streams;
  }
}
