import { Static, Type } from "@sinclair/typebox";
// TypeBox allows one to create a single unified type that can be both statically checked by the TypeScript compiler and runtime asserted using standard JSON schema validation.

// Generic

export const ErrorWithMessageSchema = Type.Object({ message: Type.String() });
export type ErrorWithMessageType = Static<typeof ErrorWithMessageSchema>;

// Auth
export const URL_SIGNUP = "/auth/signup"
export const SignupBodySchema = Type.Object({
  username: Type.String({ minLength: 1, maxLength: 100 }),
});
export type SignupBodyType = Static<typeof SignupBodySchema>;

export const URL_RANDOM_USERNAME = "/auth/random-username"
export const RandomUsernameBodySchema = Type.Object({});
export type RandomUsernameBodyType = Static<typeof RandomUsernameBodySchema>;
export const RandomUsernameSuccSchema = Type.Object({ suggestedUsername: Type.String() });
export type RandomUsernameRespSuccType = Static<typeof RandomUsernameSuccSchema>;

export const URL_SEND_LOGIN_EMAIL = "/auth/send-login-email"
export const SendLoginEmailBodySchema = Type.Object({
  email: Type.String({ minLength: 3 }),
  currentUrl: Type.String()
});
export type SendLoginEmailBodyType = Static<typeof SendLoginEmailBodySchema>;

export const URL_SEND_CLAIM_EMAIL = "/auth/send-claim-email"
export const SendClaimEmailBodySchema = Type.Object({
  email: Type.String({ minLength: 3 }),
  currentUrl: Type.String()
});
export type SendClaimEmailBodyType = Static<typeof SendClaimEmailBodySchema>;


export const URL_CLAIM_EMAIL = "/auth/claim-email"
export const ClaimEmailQuerySchema = Type.Object({
  claimToken: Type.String()
});
export type ClaimEmailQueryType = Static<typeof ClaimEmailQuerySchema>;


export const LoginBodySchema = Type.Object({
  username: Type.String({ minLength: 1, maxLength: 100 }),
  email: Type.Optional(Type.String({ minLength: 3, maxLength: 1000 })),
});
export type LoginBodyType = Static<typeof LoginBodySchema>;

export const MagicLoginBodySchema = Type.Object({
  token: Type.String(),
});
export type MagicLoginBodyType = Static<typeof MagicLoginBodySchema>;

export const JwtTokenRespSuccSchema = Type.Object({ jwtToken: Type.String() });
export type JwtTokenRespSuccType = Static<typeof JwtTokenRespSuccSchema>;

// Api games
export const GameBodyApiSchema = Type.Object({
  crosswordIdent: Type.String()
});
export const GameItemApiSchema = Type.Intersect([
  GameBodyApiSchema,
  Type.Object({
    id: Type.Number(),
    createdAt: Type.String(),
    gameState: Type.Record(Type.String(), Type.Any())
  }),
]);

export type GameBodyApiType = Static<typeof GameBodyApiSchema>;
export type GameItemApiType = Static<typeof GameItemApiSchema>;
