import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { Box, FormHelperText, Button } from '@mui/material';
import { GrSend } from "react-icons/gr";

import { SendLoginEmailBodyType, URL_SEND_LOGIN_EMAIL } from "../appshared/apiSchema";
import { apiPost } from "../utils/puzzliveApi";
import { DisplayableError } from '../utils/api';
import EmailField from "./formFields/EmailField";

type LoginSendEmailFormProps = {
  onSent: () => void;
}

const SendLoginEmailForm: FunctionComponent<LoginSendEmailFormProps> = ({ onSent }) => {

  const {
    control,
    formState,
    handleSubmit,
    setError,
  } = useForm();

  const onSubmit = async ({ email }: SendLoginEmailBodyType) => {
    try {
      await apiPost<null, SendLoginEmailBodyType>(URL_SEND_LOGIN_EMAIL, { email, currentUrl: window.location.toString() })
      onSent()
    } catch (err) {
      if (err instanceof DisplayableError) {
        setError("submit", { type: "api", message: err.message })
      } else {
        throw err
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Box>
        <EmailField name="email" control={control} />

        <FormHelperText error={!!formState.errors.submit}>
          {formState.errors.submit?.message}
        </FormHelperText>

        <Button type="submit" startIcon={<GrSend />} variant="contained" >Send Email</Button>
      </Box>

    </form>
  )
}

export default SendLoginEmailForm;