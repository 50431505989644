import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { BsBackspace } from "react-icons/bs";

import styled from "styled-components";

const KEYS = [
  // In American qwerty keyboard order
  "QWERTYUIOP",
  "ASDFGHJKL",
  "ZXCVBNM<",
];

const KeyboardElem = styled.div`
  padding-top: 1px;
  background-color: black;
  text-align: center;
`;

const KeyElem = styled.div`
  display: inline-block;
  width: ${(props) => (props.wide ? "12vw" : "9.7vw")};
  height: 10vw;
  border: 0.5px solid black;
  background-color: #ddd;
  box-sizing: border-box;
  font-size: 5vw;
  padding-top: 2vw;
  cursor: pointer;
  color: black;
  border-radius: 2px;
  vertical-align: top;

  &:active {
    background-color: blue;
  }
`;

function Keyboard({ onBackspaceEmit, onCharEmit }) {
  return (
    <KeyboardElem>
      {map(KEYS, (row, i) => (
        <div key={i}>
          {map(row, (char) => (
            <KeyElem
              key={char}
              wide={char === "<"}
              onClick={char === "<" ? onBackspaceEmit : () => onCharEmit(char)}
            >
              {char === "<" ? <BsBackspace /> : char}
            </KeyElem>
          ))}
        </div>
      ))}
    </KeyboardElem>
  );
}

Keyboard.propTypes = {
  onBackspaceEmit: PropTypes.func.isRequired,
  onCharEmit: PropTypes.func.isRequired,
};

export default Keyboard;
