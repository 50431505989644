
import _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import { api } from "../utils/puzzliveApi";
import useFetchCrosswords from "../hooks/useFetchCrosswords";

import { GameItemApiType, GameBodyApiType } from "../appshared/apiSchema";
import CrosswordDateChooser from "./CrosswordDateChooser";
import JwtTokenContext from "../contexts/jwtTokenContext";
import styled from "styled-components";
import { dateObjToStr } from "../utils/date";
import {
  LEAST_RECENT_CROSSWORD_DATE,
  MOST_RECENT_CROSSWORD_DATE,
} from "../const";

import { calcCompletionFactor } from "./crossword/gameLib";
import { MutableGameState } from "../appshared/types";
import { H3 } from "./Headers";
import GameItem, { GameEl, GameElWrapper } from "./GameItem";
import { SerializedCrossword } from "./crossword/types";


const GamesBox = styled.div`
`;


type GamesProps = {
  onGameChoose: (game: GameItemApiType) => void;
  jwtToken: string;
};

type GameStuff = {
  game: GameItemApiType
  crossword: SerializedCrossword
  completionFactor: number | null
}


const Games: FunctionComponent<GamesProps> = ({ jwtToken, onGameChoose }) => {
  const [games, setGames] = useState<Array<GameItemApiType> | null>(null);
  const [isCreatingGame, setIsCreatingGame] = useState<boolean>(false);
  const gameDateStrs = useMemo(() => games ? games.map((game) => game.crosswordIdent) : [], [games])
  const { crosswords } = useFetchCrosswords(gameDateStrs)

  const isLoading = games === null || crosswords === null
  const gameStuffs: Array<GameStuff> = (isLoading || crosswords === null) ? [] : _.map(games, (game, index) => (
    {
      game,
      crossword: crosswords[index],
      completionFactor: crosswords[index] ? calcCompletionFactor(crosswords[index], game.gameState as MutableGameState) : null
    }
  ))
  const gameStuffsByStatus = _.groupBy(gameStuffs, ({ completionFactor: cf }) => cf === 1 ? "completed" : "inprogress")

  useEffect(() => {
    api({ jwtToken })
      .get<{ data: Array<GameItemApiType> }>("/api/games")
      .then(({ data }) => {
        setGames(data);
      })
      .catch((error) => {
        console.error("got games error:", error);
      });
  }, [jwtToken]);

  async function createGame(dateStr: string) {
    return (
      await api({ jwtToken }).post<{ data: GameItemApiType }, GameBodyApiType>(
        "/api/games",
        { crosswordIdent: dateStr }
      )
    ).data;
  }


  return (
    <GamesBox className="Games">
      <Modal
        isOpen={isCreatingGame}
        onRequestClose={() => setIsCreatingGame(false)}
        style={{ content: { maxWidth: "600px", margin: "auto" } }}
      >
        Choose a NYTimes puzzle (between{" "}
        {dateObjToStr(LEAST_RECENT_CROSSWORD_DATE)}-
        {dateObjToStr(MOST_RECENT_CROSSWORD_DATE)})
        <CrosswordDateChooser
          onDateChoose={async (dateStr) => {
            onGameChoose(await createGame(dateStr));
          }}
        />
      </Modal>


      <H3>Puzzles</H3>

      <GameElWrapper>
        <GameEl className="GameCreateNew" onClick={() => setIsCreatingGame(true)}>
          Start New Game
        </GameEl>
      </GameElWrapper>


      {isLoading
        ? "Loading..."
        : !gameStuffsByStatus || !gameStuffsByStatus.inprogress
          ? "No games"
          :
          <>
            {_.map(gameStuffsByStatus.inprogress, ({ game, completionFactor }) => (

              <GameItem
                key={game.id}
                game={game}
                completionFactor={completionFactor}
                onChoose={() => onGameChoose(game)}
              />
            ))}
          </>
      }

      {isLoading
        ? "Loading..."
        : !gameStuffsByStatus
          ? null
          : !gameStuffsByStatus.completed
            ? null
            :
            <div>
              <H3>Completed</H3>
              {_.map(gameStuffsByStatus.completed, ({ game, completionFactor }) => (

                <GameItem
                  game={game}
                  completionFactor={completionFactor}
                  onChoose={() => onGameChoose(game)}
                />
              ))}
            </div>
      }
    </GamesBox>
  );
};

type GamesWrapWithTokenProps = {
  onGameChoose: (game: GameItemApiType) => void;
};

const GamesWrapWithToken: FunctionComponent<GamesWrapWithTokenProps> = ({
  onGameChoose,
}) => {
  return (
    <JwtTokenContext.Consumer>
      {({ jwtToken }) => {
        if (!jwtToken) {
          return "Not logged in";
        }
        return <Games {...{ jwtToken, onGameChoose }} />;
      }}
    </JwtTokenContext.Consumer>
  );
};

export default GamesWrapWithToken;
